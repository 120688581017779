
import { useSecondModal } from "@helpers/hooks/useSecondModal";
import AccessState from "./components/AccessState";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ShipReturn from "./components/ShipReturn";
import CommonQuestion from "./components/CommonQuestion";
import HowToPurchase from "./components/HowToPurchase";
import { useRecoilState } from 'recoil'
import { aboutUs } from '@atoms';
import './style.css'
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getUserImages } from "@api";
import { useEffect, useState } from "react";

const AboutUsMobile = ({style}) => {
	const modal = useSecondModal();
	const [aboutStatus,] = useRecoilState(aboutUs);

	const [existData, setExistData] = useState(false);

	useEffect(() => {
	  getUserImages().then(({data}) => {
		setExistData(false);
		if (data.data.length) {
		  setExistData(true);
		  localStorage.setItem('dataExist', 1);
		} else {
		  localStorage.setItem('dataExist', 0);
		  setExistData(false);
		}
	  });
	  if (!localStorage.getItem("frameSelected")) {
		localStorage.setItem("frameSelected", "clean");
	  }
	}, [])
  




	let title = () => {
		if (aboutStatus === 'SR') return 'משלוחים והחזרות';
		if (aboutStatus === 'CQ') return 'שאלות נפוצות';
		if (aboutStatus === 'PP') return 'מדיניות פרטיות';
		if (aboutStatus === 'AS') return 'תושיגנ תרהצה'.split("").reverse().join("");
		if (aboutStatus === 'HT') return 'כיצד לרכוש בבלנדס';
		return '';
	}
	return (
		<div className={`aboutus_mobile${aboutStatus === 'HT' ? '_ht' : ''}`}>
			<div className={`aboutus_mobile_header${aboutStatus === 'HT' ? '_ht' : ''}`}>
				{title()}
			</div>
			
			<div className="aboutus_mobile_back_btn"  onClick={() => {
					modal('close', 'aboutUsMobile')
				}}> {`>`}</div>
			<div className={`aboutus_mobile_content${aboutStatus === 'HT' ? '_ht' : ''}`}>
				{ aboutStatus === 'AS' && <AccessState />}
				{ aboutStatus === 'PP' && <PrivacyPolicy />}
				{ aboutStatus === 'SR' && <ShipReturn />}
				{ aboutStatus === 'CQ' && <CommonQuestion />}
				{ aboutStatus === 'HT' && <HowToPurchase /> }
			</div>
			<div className={`aboutus_mobile_footer${aboutStatus === 'HT' ? '_ht' : ''}`}>
                    { existData || localStorage.getItem('dataExist') == 1 ? (
					<NavLink onClick={() =>  { modal('close', 'aboutUsMobile')}} to="/review-your-images" className={`aboutus_mobile_footer_nav${aboutStatus === 'HT' ? '_ht' : ''}`}>
					ממשיכים לעצב
                    </NavLink>
                  ) : (
                    <NavLink onClick={() =>  { modal('close', 'aboutUsMobile')}} to="/upload-your-image" className={`aboutus_mobile_footer_nav${aboutStatus === 'HT' ? '_ht' : ''}`}>
                     עצבו קיר משלכם
                    </NavLink>
                  )}
			</div>
		</div>
	);
}

export default AboutUsMobile;
