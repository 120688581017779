import React, { useState, useRef, useEffect } from "react";
import './VideoPlayer.css';

export const VideoPlayer = () => {
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().then(() => setIsPlaying(true)).catch(() => setIsPlaying(false));
    }
  }, []);

  const handleVideoClick = () => {
    setButtonClicked(true);
    setTimeout(() => setButtonClicked(false), 200); // Reset after animation

    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="video-container">
      <video
        className="video-player"
        ref={videoRef}
        loop
        muted
        autoPlay
        playsInline
        onClick={handleVideoClick}>
        <source
          src="https://res.cloudinary.com/dzb3a5brn/video/upload/s--Wd5zl3s_--/q_80/v1706968002/Homepage_Background_Video_enzxjh.webm"
          type="video/webm" />
        <source
          src="https://res.cloudinary.com/dzb3a5brn/video/upload/s---XvZgIef--/q_80/v1706968002/Homepage_Background_Video_enzxjh.mp4"
          type="video/mp4" />
      </video>
      <div className='video-overlay'>
        <img src="./assets/images/chooseblends.png" alt="Overlay" />
        <button className={`play-button ${buttonClicked ? 'clicked' : ''}`} onClick={handleVideoClick}>
          <img 
            src={isPlaying ? "./assets/images/pause.png" : "./assets/images/play.png"}
            alt={isPlaying ? "Pause" : "Play"} 
          />
        </button>
      </div>
    </div>
  );
};
