import React from 'react';
import './HeroSection.css';
import HeroIcons from './HeroIcons';

export const HeroSection = ( ) => {
  return (
    
    <section id="hero">
    {/* <div className="mobile-banner" style={{textAlign: "right", direction: "rtl"}}>
      <h1 style={{fontSize: "24px", color: "#212121", margin: "0"}}>זכרונות נעימים.  
      <span style={{fontSize: "24px", color: "#a8a8a8", margin: "0"}}> מלאו את הקיר בתמונות שלכם ושל אהובכם.</span>
      </h1>
    </div>

              
    <div className="hero-images">
        <img src="/assets/images/image1.png" alt="בלנדס מסגרות שנדבקות לקיר" className='hero-image-1'
              loading="lazy"/>
        <img src="/assets/images/image2.png" alt="בלנדס מסגרות שנדבקות לקיר" className='hero-image-2'
              loading="lazy"/>
    </div>
              
    <div className="hero-images-3">
        <img src="/assets/images/image3.png" alt="בלנדס מסגרות שנדבקות לקיר"
              loading="lazy"/>
    </div> */}
    <HeroIcons />
    </section>
      
  );
};
