import useWindowDimensions from "@helpers/hooks/windowDemensions";
export const Gallery = () => {
  const { height, width } = useWindowDimensions();
  const isMobile = width < 767
    return (
    <svg width={isMobile ? 30 : 50} height={isMobile ? 30 : 50} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.2499 25.8333C26.1034 25.8333 28.4166 23.5201 28.4166 20.6667C28.4166 17.8132 26.1034 15.5 23.2499 15.5C20.3964 15.5 18.0833 17.8132 18.0833 20.6667C18.0833 23.5201 20.3964 25.8333 23.2499 25.8333Z" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M33.5834 5.16667H23.2501C10.3334 5.16667 5.16675 10.3333 5.16675 23.25V38.75C5.16675 51.6667 10.3334 56.8333 23.2501 56.8333H38.7501C51.6668 56.8333 56.8334 51.6667 56.8334 38.75V25.8333" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M41.3333 12.8908L45.1049 16.6625L55.1799 6.58749" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.89746 48.9542L19.6333 40.4033C21.6741 39.0342 24.6191 39.1892 26.4533 40.765L27.3058 41.5142C29.3208 43.245 32.5758 43.245 34.5908 41.5142L45.3375 32.2917C47.3525 30.5608 50.6075 30.5608 52.6225 32.2917L56.8333 35.9083" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}
