const ShippingIcon = () => {
  return <svg width="18" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 14H13C14.1 14 15 13.1 15 12V2H6C4.5 2 3.19001 2.82999 2.51001 4.04999" stroke="#0b9371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M2 17C2 18.66 3.34 20 5 20H6C6 18.9 6.9 18 8 18C9.1 18 10 18.9 10 20H14C14 18.9 14.9 18 16 18C17.1 18 18 18.9 18 20H19C20.66 20 22 18.66 22 17V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L18.58 6.01001C18.22 5.39001 17.56 5 16.84 5H15V12C15 13.1 14.1 14 13 14H12" stroke="#0b9371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8 22C9.10457 22 10 21.1046 10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 21.1046 6.89543 22 8 22Z" stroke="#0b9371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z" stroke="#0b9371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M22 12V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L22 12Z" stroke="#0b9371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M2 8H8" stroke="#0b9371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M2 11H6" stroke="#0b9371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M2 14H4" stroke="#0b9371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
}

export default ShippingIcon;
