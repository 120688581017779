import moment from "moment";
const weekdays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
const hebrewMonths = ['ינואר' ,'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר' ,'דצמבר'];

export function hebrewFormat (month, day, weekday) {
	// const hebrewMonth = hebrewDate((new Date()).getFullYear(), month, day).month_name;
	return ' ' + weekdays[weekday] + ' ה' + day + ' ב' + hebrewMonths[month] + ' ';
}

export function nextTuesday (weekday = 4) {
	let date;
	if (moment().day() === 0) date = moment().add(0, 'week').day(weekday);
	else if 
	(moment().day() === 1) date = moment().add(1, 'week').day(weekday = 0);
	else if
	(moment().day() === 2) date = moment().add(1, 'week').day(weekday = 0);
	else if
	(moment().day() === 3) date = moment().add(1, 'week').day(weekday = 1);
	else if
	(moment().day() === 4) date = moment().add(1, 'week').day(weekday = 3);
	else if
	(moment().day() === 5) date = moment().add(1, 'week').day(weekday = 3);
	else if
	(moment().day() === 6) date = moment().add(1, 'week').day(weekday = 3);
	
	return hebrewFormat(date.month(), date.date(), weekday)
}


