import React, { useState } from "react";
import { useFormik } from "formik";
import valid from "card-validator";
import axios from "axios";
import {useHistory} from "react-router-dom";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import StyledSelect from "@shared/components/Select";
import Input from "@shared/components/Input";
import HeartLoader from "@shared/HeartLoader";
import { shouldValidateState, selectedShippingAddress, imagesBlobState, secondaryModals, selectedPaymentMethod, secondOverlayState } from '@atoms';
import { netPriceState, imageCountState } from "@atoms/priceCalc";
import {OrderStatus} from "../../../constants/enums/orderStatus";
import AmountCharged from "../../../helpers/AmountCharged";
import "./style.css";
import {useSecondModal} from "../../../helpers/hooks/useSecondModal";
import {paymentErrorsConfig} from "../../../helpers/PaymentErrors";
import {validCoupon} from "../../../Recoil/Atoms";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SHIPMENT_COST = 29.90;

const AddCardFormMobile = () => {
  const [cardErr, setCardErr] = useState({
    cardNumberErr: false,
    cardHolderErr: false,
    cardExpMonErr: false,
    cardExpYearErr: false,
    cardCVVErr: false,
    cardIdErr: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [verifyErr, setVerifyErr] = useState('');
  const setSelectedPayment = useSetRecoilState(selectedPaymentMethod);
  const selectedPayment = useRecoilValue(selectedPaymentMethod);
  const setModals = useSetRecoilState(secondaryModals);
  const imageCount = useRecoilValue(imageCountState);
  const [numberOfImages,] = useState(5);
  const selectedCoupon = useRecoilValue(validCoupon);

  const netPrice = useRecoilValue(netPriceState);
  const [, setOverlay] = useRecoilState(secondOverlayState);
  const validation = useRecoilValue(shouldValidateState);
  const selectedAddress = useRecoilValue(selectedShippingAddress);
  const imagesBlob = useRecoilValue(imagesBlobState);
  const modal = useSecondModal();
  const amountCharged = AmountCharged(imageCount + imagesBlob.length, numberOfImages, netPrice + (imagesBlob.length * 45), SHIPMENT_COST, selectedCoupon);
  const history = useHistory();
  const [paymentError, setPaymentError] = useState("");

  const getCardExpDate = (month, year) => {
    const shortYear = year % 100;
    let shortYearStr = shortYear < 10 ? '0' + shortYear.toString() : shortYear.toString();
    return `${month}${shortYearStr}`;
  }

  const getCurrentMonthString = () => {
    const month = new Date().getMonth()+1;
    if (month > 10) return `${month}`;
    return `0${month}`;
  }

  const verifyCard = async (card) => {
    try {
      const res = await axios.post(BASE_URL+'/payment/do', {
        card: {
          no: card.cardNumber.replace(/\s/g, ''),
          expdate: getCardExpDate(card.expiriedMonth, card.expiriedYear),
          cvv: card.cvv,
          id: card.id,
        },
        validation: true,
        amount: (1),
      });

      if(res.data.status !== 200){
        if(res.data.errorCode in paymentErrorsConfig){
          setPaymentError(paymentErrorsConfig[res.data.errorCode].error)
        } else {
          setPaymentError("תקלה בכרטיס האשראי");
        }
        throw new Error('Verification failed.');
      }

      return true;
    } catch (err) {
      console.log(err)
      return false;
    }
  }

  const createOrder = async (data) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const res = await axios.post(`${BASE_URL}/user/createorder`, data, config);
      if (res.data.status === 200) {
        console.log('----success-----')
      }
      return res.data;
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  const handleTranzilarPayment = async (values) => {
    try {
      setLoading(true);

      if(localStorage.getItem("orderId")) {
        const orderId = localStorage.getItem("orderId");

        const checkRes = await axios.get(`${BASE_URL}/user/checkorder/${orderId}`);

        if(checkRes.data && checkRes.data.orderStatus === OrderStatus.NOT_PAID) {
          const response = await axios.post(BASE_URL + '/payment/do', {
            card: {
              no: values.cardNumber.replace(/\s/g, ''),
              expdate: getCardExpDate(values.expiriedMonth, values.expiriedYear),
              cvv: values.cvv,
              email: selectedAddress.email,
              id: values.id,
            },
            amount: amountCharged,
          });

          if(response.data.status !== 200){
            if(response.data.errorCode in paymentErrorsConfig){
              setPaymentError(paymentErrorsConfig[response.data.errorCode].error)
            } else {
              setPaymentError("תקלה בכרטיס האשראי");
            }
            throw new Error('Payment failed.');
          }

          const orderData = await axios.post(`${BASE_URL}/user/changeorderstatus/${orderId}`, {
            status: OrderStatus.PAID,
            amountCharged: amountCharged,
          });

          if (orderData.data.status === 400) {
            throw new Error('Completing order failed.');
          }

          handleCloseCardForm();
          localStorage.clear();
          history.push(`/payment-success/${orderId}`);
        } else {
          console.log("Order already paid or failed");
          localStorage.removeItem("orderId");
        }
      } else {
        const response = await axios.post(BASE_URL + '/payment/do', {
          card: {
            no: values.cardNumber.replace(/\s/g, ''),
            expdate: getCardExpDate(values.expiriedMonth, values.expiriedYear),
            cvv: values.cvv,
            email: selectedAddress.email,
            id: values.id,
          },
          amount: amountCharged,
        });

        if(response.data.status !== 200){
          if(response.data.errorCode in paymentErrorsConfig){
            setPaymentError(paymentErrorsConfig[response.data.errorCode].error)
          } else {
            setPaymentError("תקלה בכרטיס האשראי");
          }
          throw new Error('Payment failed.');
        }

        const orderData = await createOrder({ ...selectedAddress, uid: localStorage.getItem('uniqueUserId'), finalAmount: amountCharged, orderStatus: OrderStatus.PAID });
        if (!orderData) {
          throw new Error('Creating order failed.');
        }

        handleCloseCardForm();
        localStorage.clear();
        history.push(`/payment-success/${orderData.oid}`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      cardHolder: "",
      cardNumber: "",
      expiriedMonth: getCurrentMonthString(),
      expiriedYear: new Date().getFullYear(),
      cvv: "",
      id: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      setPaymentError("");
      const numberValidation = valid.number(values.cardNumber.replace(/s/g, '').replace(/_/g, '')).isPotentiallyValid && !!values.cardNumber;
      const holderValidation = valid.cardholderName(values.cardHolder).isPotentiallyValid && !!values.cardHolder;
      const expMonValidation = valid.expirationMonth(values.expiriedMonth).isPotentiallyValid && !!values.expiriedMonth;
      const expYearValidation = valid.expirationYear(values.expiriedYear.toString()).isPotentiallyValid && !!values.expiriedYear.toString();
      const cvvValidation = valid.cvv(values.cvv).isPotentiallyValid && !!values.cvv;
      if (!numberValidation || !holderValidation || !expMonValidation || !expYearValidation || !cvvValidation) {
        setCardErr({
          cardNumberErr: !numberValidation,
          cardHolderErr: !holderValidation,
          cardExpMonErr: !expMonValidation,
          cardExpYearErr: !expYearValidation,
          cardCVVErr: !cvvValidation,
          cardIdErr: !values.id,
        })
        setLoading(false);
        return;
      }

      if(validation) {
        const isValid = await verifyCard(values);

        if (!isValid) {
          /*setVerifyErr('Card Verfication failed.');*/
          setLoading(false);
          return;
        }

        let hebrewType = '';
        const cardType = valid.number(values.cardNumber.replace(/s/g, '')).card.type;

        if (cardType === 'visa') hebrewType = 'ויזה';
        else if (cardType === 'mastercard') hebrewType = 'מאסטרקארד';
        else if (cardType === 'diners') hebrewType = 'דיינרס';
        else if (cardType === 'amex') hebrewType = 'אמריקן אקספרס';
        else hebrewType = 'כרטיס אשרא';
        setSelectedPayment({...values, hebrewType});
        setLoading(false);
        handleCloseCardForm();
      } else {
        await handleTranzilarPayment(values);
      }
    },
  });

  const monthArray = [...Array(12)].map((_,index) => {
    if (index >= 9) {
      return ({ value: `${index+1}`, label: `${index+1}` })
    }
    return ({ value: `0${index+1}`, label: `0${index+1}` })
  });

  const generateArrayOfYears = () => {
    const min = new Date().getFullYear()
    const max = min + 10
    const years = []

    for (var i = min; i <= max; i++) {
      years.push({ value: i, label: i })
    }
    return years
  }

  const handleCloseCardForm = () => {
    setModals(state => ({
      ...state,
      addCardMobile: { visible: false },
      selectCardMobile: { visible: false },
    }))
    setOverlay(false);
  }

  return (
    <form className="add-new-card-form-mobile" onSubmit={formik.handleSubmit}>
      <div className="add-new-card-form-mobile__header">
        <span onClick={() => handleCloseCardForm()}>&gt;</span>
        <span>תשלום מאובטח</span>
      </div>
      <div className="add-new-card-form-mobile__content">
        <div className="form__fields-mobile">
          <div className="form__fields-mobile--row no-bottom-radius">
            <div style={cardErr.cardHolderErr ? {border: "1px solid red", borderRadius: "6px"} : {}}>
              <Input
                name="cardHolder"
                onChange={formik.handleChange}
                value={formik.values.cardHolder}
                placeholder="שם בעל הכרטיס"
                style={{direction: "rtl"}}
              />
            </div>
          </div>
          <div className="form__fields-mobile--row no-top-radius" style={{marginTop: "-1px"}}>
            <div style={cardErr.cardNumberErr ? {border: "1px solid red", borderRadius: "6px"} : {}}>
              <Input
                name="cardNumber"
                mask=""
                type="tel"
                alwaysShowMask={false}
                onChange={(e) => {
                  let number = e.target.value.replace(/\s/g, '').replace(/_/g, '');
                  if (number.startsWith('34') || number.startsWith('37') || number.startsWith('123')) {
                    setCardErr({...cardErr, cardNumberErr: true});
                  } else {
                    setCardErr({...cardErr, cardNumberErr: false});
                  }
                  if (number.length > 8) {
                    if (cardErr.cardNumberErr) {
                      e.target.value = number.substr(0, 8);
                      number = number.substr(0, 8);
                    }
                  }
                  const joy = number.match(/.{1,4}/g);
                  if (joy) e.target.value = joy.join(' ');
                  formik.handleChange(e)}
                }
                placeholder="מספר כרטיס"
                value={formik.values.cardNumber}
                maxLength={19}
                style={{direction: "rtl"}}
                isFlexible={true}
              />
            </div>
          </div>
          <br />
          <div className="form__fields-mobile--row">
            <div className="cvv_content">
              <div className="cvv_content" style={cardErr.cardCVVErr ? {border: "1px solid red", borderRadius: "6px"} : {}}>
                <Input
                  name="cvv"
                  onChange={formik.handleChange}
                  value={formik.values.cvv}
                  mask=""
                  type="tel"
                  placeholder="קוד בטחון (CVV)"
                  maxLength={3}
                  style={{direction: "rtl"}}
                  isFlexible={true}
                />
                {/* <img
                  src="/assets/file/images/CVV_icon.png"
                  style={{position: "absolute", top: "50%", left: "15px", transform: "translate(0%, -50%)"}}
                /> */}
              </div>
            </div>
          </div>
          <br/>
          <div className="form__fields-mobile--row">
            <div className="cvv_content">
              <div className="cvv_content" style={cardErr.cardIdErr ? {border: "1px solid red", borderRadius: "6px"} : {}}>
                <Input
                  name="id"
                  onChange={formik.handleChange}
                  value={formik.values.id}
                  mask=""
                  placeholder="תעודת זהות של בעל הכרטיס"
                  style={{direction: "rtl"}}
                />
              </div>
            </div>
          </div>
          <div className="form__fields-mobile--row">
            <span style={{lineHeight: "36px", marginRight: "6px", marginBottom: "5px"}}>תוקף</span>
            <div className="selects">
              <div className="selects" style={(cardErr.cardExpMonErr || cardErr.cardExpYearErr) ? {border: "1px solid red", borderRadius: "6px"} : {}}>
                <StyledSelect
                  name="expiriedMonth"
                  options={monthArray}
                  placeholder={false}
                  onChange={(i) => formik.setFieldValue('expiriedMonth', i.value)}
                  defaultValue={monthArray.find(itm => itm.value === formik.values.expiriedMonth)}
                  isSearchable={false}
                />
                <StyledSelect
                  name="expiriedYear"
                  options={generateArrayOfYears()}
                  placeholder={false}
                  onChange={(i) => formik.setFieldValue('expiriedYear', i.value)}
                  defaultValue={generateArrayOfYears().find(itm => itm.value === formik.values.expiriedYear)}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>
          {paymentError && (
            <div className="form__fields-mobile--row" style={{color: "red", marginTop: "20px"}}>
              {paymentError}
            </div>
          )}
          {/*{verifyErr && (
            <div className="form__fields-mobile--row" style={{color: "red", marginTop: "20px"}}>
              {verifyErr}
            </div>
            )
          }*/}
        </div>
      </div>
      <div className="add-new-card-form-mobile__footer">
        {validation ? (
          <button className="submit" type="submit">
            אישור כרטיס
          </button>
        ) : (
          <button className="submit-without-validation" type="submit">
            אישור והזמנה
          </button>
        )}
      </div>
      <div style={{display: "flex", padding: "20px", alignItems: "center", justifyContent: "space-between"}}>
        <div style={{width: "135px", height: "0px", border: "1px solid #CECECE"}}></div>
        <img src="/assets/file/images/mobile-card-footer-img.PNG" width="100%" alt=""/>
        <div style={{width: "135px", height: "0px", border: "1px solid #CECECE"}}></div>
      </div>
      <HeartLoader isLoading={isLoading} showText={false}/>
    </form>
  );
};

export default AddCardFormMobile;
