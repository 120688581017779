import React, {useCallback, useState} from 'react'
import "./third-onboard-screen.css"
import { Formik } from "formik";
import * as Yup from "yup";
import {
    modalGiftState,
} from "@atoms";
import {useRecoilState} from "recoil";
import useWindowDimensions from "../../../helpers/hooks/windowDemensions";
import axios from "axios";
import {formUploadState} from "../../../Recoil/Atoms";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ThirdOnboardScreen = ({show, onClose, onClickOut, onSkip}) => {
    const [gift,] = useRecoilState(modalGiftState);
    const {height, width} = useWindowDimensions();
    const [uploadState, setUploadState] = useRecoilState(formUploadState);

    const [focus, setFocus] = useState({
        email: false,
        name: false,
    });

    const currentDate = new Date();
    const idtTimeZone = 'Asia/Jerusalem';
    const options = { timeZone: idtTimeZone };
    const idtDate = new Date(currentDate.toLocaleString('en-US', options));

    const padTwoDigits = (num) => {
        return num.toString().padStart(2, "0");
    }

    const dateFormat = (date, dateDivider = "/") => {
        return (
          [
              padTwoDigits(date.getDate()),
              padTwoDigits(date.getMonth() + 1),
              date.getFullYear().toString().slice(-2),
          ].join(dateDivider) +
          "-" +
          [
              padTwoDigits(date.getHours()),
              padTwoDigits(date.getMinutes()),
              padTwoDigits(date.getSeconds()),
          ].join(":")
        );
    }

    const formattedDate = dateFormat(idtDate);

    const putData = useCallback(async (data) => {
        const names = data.fullName.trim().split(' ');
        const firstName = names[0];
        const lastName = names.length > 1 ? names.slice(1).join(' ') : '';
        try {
            const response = await axios.post(BASE_URL + "/user/register", {
                customerName: data.fullName,
                email: data.email,
                selfOrGift: data.selfOrGift,
                agreed: data.agreed,
                date: formattedDate,
            });
        } catch (err) {
            console.log(err);
        }
    }, []);

    const validationSchema = Yup.object({
        fullName:
            Yup.string()
                .min(1, 'שם קצר מדי')
                .max(50, 'שם ארוך מדי')
                .required('שדה חובה'),
        email:
            Yup.string()
                .required('שדה חובה')
                .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ , "Invalid email"),
    });

    const handleOnSubmit = (values) => {
        if (values.agreed) {
          values.agreed = "joined";
          if (window.flashy && typeof window.flashy.contacts.create === 'function') {
            const names = values.fullName.trim().split(' ');
            const firstName = names[0];
            const lastName = names.length > 1 ? names.slice(1).join(' ') : '';
            window.flashy.contacts.create({
              "email": values.email,
              "first_name": firstName,
              "last_name": lastName,
            }, 20215);
          }
        } else {
          values.agreed = "refused";
        }
        values.selfOrGift = gift;
        putData(values);
        localStorage.setItem('loginCompleted', 1);
        setUploadState(true);
        onClose();
      };

    return (
        <div onClick={onClickOut} className={`third-screen ${show ? "show" : ""}`}>
            <div onClick={e => e.stopPropagation()} className="third-screen-content">
                <div className="third-screen-header">
                    {width > 767 ? (
                        <div className="third-screen-container">
                            <div className="third-screen-top-text">
                                <p className="third-screen-first-top-text">סיכום <br/>ומשלוח</p>
                                <p>בחירת<br/>תמונות</p>
                            </div>
                            <div className="third-screen-under-top-text">
                                <div className="third-screen-first-under-top-text"></div>
                                <div></div>
                            </div>
                            <ul className="third-screen-progressbar">
                                <li></li>
                                <li></li>
                                <li className="third-screen-active">
                                    <div className="third-screen-active-inside"></div>
                                </li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                
                {width > 767 ? (
                <div className="third-screen-body">
                    <Formik
                        initialValues={{
                            fullName: "",
                            email: "",
                            selfOrGift: "",
                            agreed: true,
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={validationSchema}
                        onSubmit={(values) => handleOnSubmit(values)}
                    >
                        {({ handleSubmit, handleChange,
                            values, errors,
                            setFieldValue, validateField,
                          }) => (
                            <form onSubmit={handleSubmit} id="form">
                                <h5 className="third-screen-h"><span class="emoji">😊</span> מעולה</h5>
                                <p className="third-screen-form-text">נתחיל עם פרטים כמו שמך הפרטי ואימייל</p>
                                <div className="third-screen-first-input">
                                    <input className={`third-screen-input-name ${focus.name ? "focus" : errors.fullName ? "error" : ""}`}
                                           id="fullName"
                                           name="fullName"
                                           type="fullName"
                                           placeholder="מה השם שלך?"
                                           onChange={handleChange}
                                           onFocus={() => {setFocus({
                                               name: true,
                                           })}}
                                           onBlur={() => {
                                               validateField('fullName');
                                               setFocus({
                                                   name: false,
                                               });
                                           }}
                                           value={values.fullName}
                                    />
                                    <img className="third-screen-icon" src={`../../assets/images/user-square${focus.name ? "-focus" : errors.fullName ? "-error" : ""}.svg`} alt={"email icon"}></img>
                                </div>
                                <div className="third-screen-second-input">
                                    <input className={`third-screen-input-email ${focus.email ? "focus" : errors.email ? "error" : ""}`}
                                           id="email"
                                           name="email"
                                           type="email"
                                           placeholder="מה כתובת המייל שלך?"
                                           onChange={handleChange}
                                           onFocus={() => {setFocus({
                                               email: true,
                                           })}}
                                           onBlur={() => {
                                               validateField('email');
                                               setFocus({
                                                   email: false,
                                               });
                                           }}
                                           value={values.email}
                                    />
                                    <img className="third-screen-icon" src={`../../assets/images/onboarding-email${focus.email ? "-focus" : errors.email ? "-error" : ""}.svg`} alt={"email icon"}></img>
                                </div>
                                <div className="third-screen-checkbox-container">
                                    <label className="third-screen-checkbox-label" htmlFor="agreed">נא לשלוח לי עדכונים בנוגע להטבות ואירועים</label>
                                    <input className="third-screen-checkbox"
                                           type="checkbox"
                                           id="agreed"
                                           name="agreed"
                                           onChange={() => setFieldValue("agreed", !values.agreed)}
                                    />
                                </div>
                                <button
                                    className="third-screen-button-upload"
                                    type='submit'
                                    disabled={uploadState}
                                >
                                    <p className="third-screen-text-button">בוחרים תמונות</p>
                                </button>
                            </form>
                        )}
                    </Formik>
                    <p className="third-screen-skip" onClick={onSkip}>דלג</p>
                </div> ) : (
                                <div className="third-screen-body">
                                <Formik
                                    initialValues={{
                                        fullName: "",
                                        email: "",
                                        selfOrGift: "",
                                        agreed: true,
                                    }}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => handleOnSubmit(values)}
                                >
                                    {({ handleSubmit, handleChange,
                                        values, errors,
                                        setFieldValue, validateField,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="form">
                                            <h5 className="third-screen-h-mob">מעולה<span class="emoji">😊</span></h5>
                                            <p className="third-screen-form-text">נתחיל עם פרטים כמו שמך הפרטי ואימייל</p>
                                            <div className="third-screen-first-input">
                                                <input className={`third-screen-input-name ${focus.name ? "focus" : errors.fullName ? "error" : ""}`}
                                                       id="fullName"
                                                       name="fullName"
                                                       type="fullName"
                                                       placeholder="מה השם שלך?"
                                                       onChange={handleChange}
                                                       onFocus={() => {setFocus({
                                                           name: true,
                                                       })}}
                                                       onBlur={() => {
                                                           validateField('fullName');
                                                           setFocus({
                                                               name: false,
                                                           });
                                                       }}
                                                       value={values.fullName}
                                                />
                                                <img className="third-screen-icon" src={`../../assets/images/user-square${focus.name ? "-focus" : errors.fullName ? "-error" : ""}.svg`} alt={"email icon"}></img>
                                            </div>
                                            <div className="third-screen-second-input">
                                                <input className={`third-screen-input-email ${focus.email ? "focus" : errors.email ? "error" : ""}`}
                                                       id="email"
                                                       name="email"
                                                       type="email"
                                                       placeholder="מה כתובת המייל שלך?"
                                                       onChange={handleChange}
                                                       onFocus={() => {setFocus({
                                                           email: true,
                                                       })}}
                                                       onBlur={() => {
                                                           validateField('email');
                                                           setFocus({
                                                               email: false,
                                                           });
                                                       }}
                                                       value={values.email}
                                                />
                                                <img className="third-screen-icon" src={`../../assets/images/onboarding-email${focus.email ? "-focus" : errors.email ? "-error" : ""}.svg`} alt={"email icon"}></img>
                                            </div>
                                            <div className="third-screen-checkbox-container">
                                                <label className="third-screen-checkbox-label-mob" htmlFor="agreed">נא לשלוח לי עדכונים בנוגע להטבות ואירועים</label>
                                                <input className="third-screen-checkbox"
                                                       type="checkbox"
                                                       id="agreed"
                                                       name="agreed"
                                                       onChange={() => setFieldValue("agreed", !values.agreed)}
                                                />
                                            </div>
                                            <button
                                                className="third-screen-button-upload-mob"
                                                type='submit'
                                                disabled={uploadState}
                                            >
                                                <p className="third-screen-text-button-mob">בוחרים תמונות</p>
                                            </button>
                                        </form>
                                    )}
                                </Formik>

                            </div> 
                            ) }
                <div className="third-screen-footer">
                    <p className='onboarding-indicator'>2/2</p>
                    <div className="third-screen-progress-skip">
                    <p className="third-screen-skip-mob" onClick={onSkip}>דלג</p>
                        <div className="third-screen-progress">
                            <div className="third-screen-first-circle"></div>
                            <div className="third-screen-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThirdOnboardScreen;
