import React from 'react';
import './HeroIcons.css'; // Import the CSS file

const HeroIcons = () => {
    return (
        <div className="heroicons" dir="rtl">
            <div className="icon-container">
                <span className="icon-text">אין צורך במסמרים</span>
                <i className="icon">
                    <img src='/assets/images/mobnails.svg' loading="lazy"/>
                </i>
            </div>
            <div className="icon-container">
                <span className="icon-text">הדפסה איכותית</span>
                <i className="icon">
                    <img src='/assets/images/mobquality.svg' loading="lazy"/>
                </i>
            </div>
            <div className="icon-container">
                <span className="icon-text">משלוחים לכל הארץ</span>
                <i className="icon">
                    <img src='/assets/images/mobship.svg' loading="lazy"/>
                </i>
            </div>
        </div>
    );
};

export default HeroIcons;