import React from 'react'
import "./style.css"
import {useSetRecoilState} from "recoil";
import {useSecondModal} from "@helpers/hooks/useSecondModal";
import useWindowDimensions from "../../../helpers/hooks/windowDemensions";
import {addressLinkState} from "@atoms";

const AddressLink = ({show}) => {
  const {height, width} = useWindowDimensions();
  const setAddressLink = useSetRecoilState(addressLinkState);
  const modal = useSecondModal();

  const handleClose = () => {
    setAddressLink(false);
    if (width < 767){
      modal("open", "addAddressMobile");
      modal("close", "selectCardMobile");
    } else {
      modal("open", "addAddress");
      modal("close", "selectCard");
    }
  }

  const handleClickOut = (event) => {
    if (event.target.className === "address-link show") {
      setAddressLink(false);
    }
  };

  return (
    <div
      onClick={(event) => handleClickOut(event)}
      className={`address-link ${show ? "show" : ""}`}>
      <div className="address-link-content">
        <div className="address-link-header">
          <p>זיהינו שלא בחרת כתובת למשלוח</p>
        </div>
        <div className="address-link-body">
          <p>לפני שתשלמו נצטרך לדעת לאן לשלוח את החבילה</p>
        </div>
        <div onClick={() => handleClose()} className="address-link-footer">
          <img
            src="/assets/images/arrow-down.svg"
            alt="arrow left"
          />
          <p className="address-link-text">מעבר לבחירת כתובת</p>
        </div>
      </div>
    </div>
  )
}

export default AddressLink;