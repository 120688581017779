import React from "react";
import './style.css'

const MobileIcon = (props) => {
  const {text = "+", contentSize = "1.8rem", onClick, styling} = props
  return (
    <div className="mobile-icon-container" onClick={onClick} style={styling}>
      <span style={{fontSize: contentSize}}>{text}</span>    
    </div>
  );
};

export default MobileIcon;
