export const TWENTY_IMAGES_COUPONS = ['GIFT40'];
export const TWENTY_IMAGES_DISCOUNT = 30;

export const SIXTEEN_COUPONS = ['GIFT35'];
export const SIXTEEN_DISCOUNT = 26;

export const TWELVE_IMAGES_COUPONS = ['GIFT30'];
export const TWELVE_IMAGES_DISCOUNT = 26;

export const EIGHT_IMAGES_COUPONS = ['GIFT20'];
export const EIGHT_IMAGES_DISCOUNT = 10;

export const SIX_IMAGES_COUPONS = ['GIFT5']
export const SIX_IMAGES_DISCOUNT = 5;

export const FIVE_IMAGES_COUPONS = ['FIVE5OFF']
export const FIVE_IMAGES_DISCOUNT = 99;

export const THREE_IMAGES_COUPONS = ['LOVEGVSX3PP', 'LOVEGVL0GPP'];
export const THREE_IMAGES_DISCOUNT = 100;

export const TENINFLUENCER_COUPONS = ['EDEN10', 'HADAR10','EDEN15', 'NOA15', 'TAMA15'];
export const TENINFLUENCER_DISCOUNT = 15;

export const NINE_COUPONS = [];
export const NINE_DISCOUNT = 10;

export const DEFAULT_COUPON = 'מבצע קיץ';

export const discountConfig = {
  24: {
    discount: TWENTY_IMAGES_DISCOUNT,
    coupons: TWENTY_IMAGES_COUPONS,
    enableDefault: true,
    exactAmount: false,
  },
  16: {
    discount: SIXTEEN_DISCOUNT,
    coupons: SIXTEEN_COUPONS,
    enableDefault: true,
    exactAmount: true,
  },
  9: {
    discount: NINE_DISCOUNT,
    coupons: NINE_COUPONS,
    enableDefault: true,
    exactAmount: false,
  },
  
  10: {
    discount: TENINFLUENCER_DISCOUNT,
    coupons: TENINFLUENCER_COUPONS,
    enableDefault: true,
    exactAmount: true,
  },
  
  12: {
    discount: TWELVE_IMAGES_DISCOUNT,
    coupons: TWELVE_IMAGES_COUPONS,
    enableDefault: true,
    exactAmount: false,
  },
  8: {
    discount: EIGHT_IMAGES_DISCOUNT,
    coupons: EIGHT_IMAGES_COUPONS,
    enableDefault: true,
    exactAmount: false,
  },
  6: {
    discount: SIX_IMAGES_DISCOUNT,
    coupons: SIX_IMAGES_COUPONS,
    enableDefault: true,
    exactAmount: false,
  },
  5: {
    discount: FIVE_IMAGES_DISCOUNT,
    coupons: FIVE_IMAGES_COUPONS,
    enableDefault: false,
    exactAmount: true,
  },
  3: {
    discount: THREE_IMAGES_DISCOUNT,
    coupons: THREE_IMAGES_COUPONS,
    enableDefault: false,
    exactAmount: true
  }
};
