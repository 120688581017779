import './loader.css'
import {useEffect, useState} from "react";

const ImageLoader = (props) => {
    const {style, loaderClassName, className, src, loading, loadingBlob, zoomvalue, bwFilter, ...rest} = props;
    const [internalLoading, setInternalLoading] = useState(true);
    useEffect(() => {
        setInternalLoading(true)
    }, [src])

    let imgStyle = {...style};

    const isBlob = zoomvalue === "0" && loadingBlob;
    const fullClassName = isBlob ? loaderClassName : className;

    return (
        <>
            <img
              src={isBlob ? loadingBlob : src}
              className={bwFilter ? fullClassName + " grayscale-filter" : fullClassName}
              style={{...imgStyle, display: internalLoading && loadingBlob ? (isBlob ? "block" : "none") : (isBlob ? 'none' : 'block')}}
              onLoad={() => {
                  if(!isBlob){
                      setInternalLoading(false);
                  }
              }}
              alt=''
            />
            {/*{loadingBlob && <img
                className={loaderClassName}
                style={{...imgStyle, display: internalLoading && loadingBlob ? 'block' : 'none'}}
                src={loadingBlob}
                alt={''}/>}
            <img
                src={src}
                className={className}
                style={{...imgStyle, display: internalLoading && loadingBlob ? 'none' : 'block'}}
                onLoad={() => {
                    setInternalLoading(false)
                }}
                alt=''
            />*/}
            {loading && <div className="roling-loader"><img
                /*src="../assets/images/Rolling.svg"*/
                style={{position: "absolute", 'zIndex': "1000", left: '35%', top: '33%'}}
                //alt="rolling"
                alt=""
            /></div>
            }
        </>
    );
}

export default ImageLoader;
