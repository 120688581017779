import React from 'react'
import "./first-onboard-screen.css"

const FirstOnboardScreen = ({show, onClose, onClickOut}) => {
    return (
        <div onClick={onClickOut} className={`first-screen ${show ? "show" : ""}`}>
            <div onClick={e => e.stopPropagation()} className="first-screen-content">
                <div className="first-screen-header">
                    <div className="first-screen-gradient-header"></div>
                    <video
                        className="first-screen-video"
                        autoPlay={'autoplay'}
                        loop
                        muted
                        playsInline
                    >
                        <source
                            type="video/mp4"
                            src="/assets/file/videos/HomepageVideoNew.mp4"

                        />
                        <source
                            type="video/webm"
                            src="https://res.cloudinary.com/dzb3a5brn/video/upload/s--XH8GDr6b--/vc_auto/v1683386316/HomepageVideoNew_rc6vmu.webm"
                        />
                    </video>
                </div>
                <div className="first-screen-body">
                    <div className="first-screen-top-text">
                         👋 היי, טוב לראות אותך
                    </div>
                    <p className={"first-screen-body-text"}>לעצב את קירות הבית או המשרד עם בלנדס<br/>.זו דרך נהדרת להכניס רגשות וחיים לאיזור</p>
                </div>
                <div className="first-screen-footer">
                    <div className="first-screen-gradient-footer"></div>
                    <button onClick={onClose} className="first-screen-button-close">
                        <img className="first-screen-arrow-left" src="../../assets/images/arrow-left.svg" alt={"<"}></img>
                        <p className="first-screen-text-button"><b>המשך</b></p>
                    </button>
                </div>
                <div className="first-screen-progress">
                    <div className="first-screen-first-circle"></div>
                    <div className="first-screen-line"></div>
                    <div className="first-screen-second-circle"></div>
                </div>
            </div>
        </div>
    )
}

export default FirstOnboardScreen;
