export const paymentErrorsConfig = {
  "001": {
    error: "תקלה בכרטיס האשראי"
  },
  "002": {
    error: "תקלה בכרטיס האשראי"
  },
  "003": {
    error: "תקלה בכרטיס האשראי"
  },
  "004": {
    error: "תקלה בכרטיס האשראי"
  },
  "005": {
    error: "תקלה בפרטי כרטיס האשראי"
  },
  "006": {
    error: "CVV שגיאה במספר"
  },
  "009": {
    error: "תקלת רשת, נסו שוב בעוד מספר רגעים"
  },
  "015": {
    error: "שגיאה - פג תוקף הכרטיס"
  },
  "026": {
    error: "הוכנס מזהה שגוי בת.ז"
  }
};