import './style.css';

const ShipReturn = () => {
  return (
  <div className='aboutus_shipreturn'>
    <div className='aboutus_shipreturn_table'>
      <div className='aboutus_shipreturn_table_row' style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
        <span className='aboutus_shipreturn_table_row_item shipreturn_bold'>חברת שילוח</span>
        <span className='aboutus_shipreturn_table_row_item shipreturn_bold'>עלות משלוח<br />מתחת ל225 שח</span>
        <span className='aboutus_shipreturn_table_row_item shipreturn_bold'>עלות משלוח<br />מ225 שח ומעלה</span>
        <span className='aboutus_shipreturn_table_row_item shipreturn_bold'>זמן אספקה</span>
      </div>
      <div className='aboutus_shipreturn_table_row' style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
        <span className='aboutus_shipreturn_table_row_item'>דואר ישראל</span>
        <span className='aboutus_shipreturn_table_row_item'>29.90 שח</span>
        <span className='aboutus_shipreturn_table_row_item'>חינם</span>
        <span className='aboutus_shipreturn_table_row_item'>3-7 ימי עסקים</span>
      </div>
    </div>
    <div style={{direction: "rtl", marginTop: "27px"}}>
    * החל מרגע אישור ההזמנה בחברת האשראי, בימי עסקים בלבד (ימים א'- ה') ערבי חג, ימי ו' ,שבת וחגים אינם נחשבים ימי עסקים.<br/>
משלוח עד הבית באמצעות חברת דואר ישראל בהתאם לזמני האספקה של דואר שליחים.<br/>
<br/>
*** לישובים מרוחקים 3-6 ימי עסקים נוספים, רשימת היישובים מתעדכנת מעת לעת והיא מתפרסמת באתר האינטרנט של דואר ישראל. ט.ל.ח.<br/><br/>
בבלנדס אנחנו מחויבים לדאוג לשביעות רצונכם המירבית, לכן במידה וקיבלתם מוצר פגום יש לפנות אלינו ונעזור בהתאם.
<br/>
ביטול עסקה בהתאם לתקנות הגנת הצרכן (ביטול עסקה), התשע"א-2010 וחוק הגנת הצרכן, התשמ"א-1981 <br/>
    </div>
  </div>
  )
}

export default ShipReturn;