import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowDimensions from "@helpers/hooks/windowDemensions";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./slidernew.css";

// import required modules
import { Navigation, Pagination } from "swiper";
import { CommentIcon } from "./comment";

const initialSlideIndex = 1

const review = {
  image: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--rwAPl6pN--/c_fill,g_north_west,h_320,q_100,w_305,x_0,y_0/v1690045883/2023-07-22_20-10-18_jb4pvz.webp',
  customerName: 'elinorahamim',
  review: 'מהמםםם יצא פשוט מושלם!! הקיר מנציח רגשות וזכרונות בצורה אסתטית ומהממת!',

}
const review1 = {
  image: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--F450AlEL--/c_fill,g_xy_center,h_320,q_100,w_305,x_780,y_817/v1710333340/IMG_3163_buefji.webp',
  customerName: 'h_kadosh',
  review: 'ממש ממש פשוט להדביק אותן על הקיר, תלינו בחדר יצא שלמות !!!!! תודה רבה רבה',

}
const review2 = {
  image: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--IS7b98e6--/c_fill,g_center,h_320,w_305/v1683388904/IMG_1055_ojgfor.webp',
  customerName: 'אלברט כהן',
  review: 'הקיר יצא יפה מאוד, כיף לי להסתכל על המשפחה שלי והזכרונות שבנינו ביחד',

}

const review3 = {
  image: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--x9pilTOg--/c_fill,g_xy_center,h_320,q_100,w_305,x_1118,y_759/v1695230038/16774a89-98a6-4512-a740-045244818642_s61i16.webp',
  customerName: 'ruty_fogler',
  review: 'שירות מעולה 10מ10. משלוח מהיר, הגיע תוך שלושה ימים. המוצר מהמם. אזמין עוד.',

}

const SliderHome = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [reviews, setReviews] = useState([]);
  const { height, width } = useWindowDimensions();
  const [selectedSliderIndex, setSelectedSliderIndex] = useState(initialSlideIndex)
  const [sliderProg, setSliderProg] = useState(.25)
  const [textVisibility, setTextVisibility] = useState(1)

  const getAllReviews = async () => {
    setReviews([review2, review3, review, review1])
  };

  const mapRange = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;


  useEffect(() => {
    getAllReviews();
  }, []);

  useEffect(()=>{
    const divisors = reviews.length+1
    const divisorNum = (100 / divisors) * .01;
    const divisorHalf = divisorNum / 2;

    let ranges = []
    for(let i = 0; i < divisors; i++) {
      const currPerc =  (i * divisorNum) ?? 0
      const nextPerc = ((currPerc + divisorNum) ?? 0)
      ranges.push({
        min: currPerc,
        max: nextPerc,
        mid: ((nextPerc - currPerc) / 2) + currPerc
      })
    }
    
    let visibility = .5
    const range = ranges.find((range)=>{
       return sliderProg >= range.min && sliderProg <= range.max
    })
    if(sliderProg >= range?.min && sliderProg <= range?.mid) {
      visibility = mapRange(sliderProg, range.min, range.mid, 1, 0)
    }
    if(sliderProg >= range?.mid && sliderProg <= range?.max) {
      visibility = mapRange(sliderProg, range.mid, range.max, 0, 1)
    }

    let contentRanges = []
    let currMin = divisorNum+divisorHalf;
    const firstRange = [0, divisorNum+divisorHalf];
    const lastRange = [1-divisorNum+divisorHalf, 1]
    contentRanges.push({
      index: 0,
      ranges: [firstRange, lastRange]
    })
    for(let k = 1; k < divisors-1; k++) {
      contentRanges.push({
        index: k,
        ranges: [[currMin, currMin+divisorNum]]
      })
      currMin+=divisorNum
    }

    const progressIndex = contentRanges.find((contentRange)=>{
      return contentRange.ranges.find((range)=>{
        return sliderProg > range[0] && sliderProg < range[1]
      })
    })

    setSelectedSliderIndex(progressIndex?.index ?? 0)
    setTextVisibility(visibility)

  }, [sliderProg])


  return (
    <div className="swiper-container">
      <div className="swiper-overlay-desktop"></div>
      {reviews.length > 0 ? (
        <Swiper
          initialSlide={initialSlideIndex}
          onTouchEnd={()=>setTextVisibility(1)}
          onSlideChangeTransitionEnd={()=>{setTextVisibility(1)}}
          onProgress={(swiper, progress)=>{setSliderProg(progress)}}
          onSlideChange={(swiper)=>setSelectedSliderIndex(swiper.realIndex)}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          navigation
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 19,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 19,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 29,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 29,
            }
          }}
          modules={[Navigation, Pagination]}
          className="mySwiperHome"
        >
          {reviews.map((data, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="imgBox" >
                    <img src={`${data.image}`} alt="" />
                   {width >= 767 && <div className="bgOverlay">
                      <div className="textOverlay">
                        <h5>{data.customerName}</h5>
                        <p>{data.review}</p>
                      </div>
                    </div>}
                    {width < 767 && <div className="mobile-swiper-overlay"><div className="mobileBgOverlay" /></div>}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : null}
      {reviews.length > 0 && width < 767 && 
        <div className="mobile-comment-container" style={{opacity: textVisibility}}>
          <div style={{display: "flex"}} >
            <p style={{marginRight: '10px', fontSize: '14px'}}>{reviews[selectedSliderIndex].review}</p>
            <CommentIcon style={{marginRight: '10px', minWidth: '45px'}} />
          </div >
          <div style={{alignItems: 'flex-start', display: 'flex'}}>
            <span style={{fontSize: '14px', fontWeight: 400}}>@{reviews[selectedSliderIndex].customerName}</span> </div>
        </div>}
    </div>
  );
};

export default SliderHome;
