import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/he'; // Import moment Hebrew locale

const DeliveryDateRange = () => {
  const [dateRange, setDateRange] = useState('');

  useEffect(() => {
    moment.locale('he'); // Set locale to Hebrew

    let now = moment();

    let startDate = now.clone().add(3, 'days');
    while (startDate.isoWeekday() === 6) {
      startDate.add(1, 'day');
    }

    let endDate = now.clone().add(5, 'days');
    while (endDate.isoWeekday() === 5 || endDate.isoWeekday() === 6) {
      endDate.add(1, 'day');
    }

    const startDateStr = startDate.format("D בMMMM");
    const endDateStr = endDate.format("D בMMMM");

    setDateRange(`${startDateStr} - ${endDateStr}`);
  }, []);

  return (
    <div className='delivery-review-mobile'>
      משלוח <span style={{ fontWeight: '500' }}>{dateRange}</span>
    </div>
  );
};

export default DeliveryDateRange;
