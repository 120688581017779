import {validCoupon} from '@atoms';
import {
  DEFAULT_COUPON,
  discountConfig,
} from '@helpers/discounts';
import React, {useCallback, useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import './style.css';

const Loader = () => (
  <div className='discountspinner' />
);

const AutoLoader = () => (
  <div className='discountrollback'>שחזור</div>
);

const InputCoupon = ({imageCount, netPrice}) => {
  const [isFocus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [status, setStatus] = useState('');
  const [isVisibleCoupon, setIsVisibleCoupon] = useState(false);
  const selectedCoupon = useRecoilValue(validCoupon);
  const setCoupon = useSetRecoilState(validCoupon);
  const minImagesForAutoDiscountAvailable = Object.keys(discountConfig)
    .sort((a, b) => Number(a) - Number(b)).find(key => discountConfig[key].enableDefault);

  const validateCoupon = (value) => {
    if (value === '') {
      setStatus('');
      setCoupon(null);
      return;
    }

    const maxDiscountAmountAvailable = Object.keys(discountConfig)
      .sort((a, b) => Number(b) - Number(a))
      .find((key) => Number(key) <= imageCount);

    if (value === DEFAULT_COUPON && maxDiscountAmountAvailable &&
      discountConfig[maxDiscountAmountAvailable].enableDefault) {
      const discount = discountConfig[maxDiscountAmountAvailable].discount;
      const finalPrice = netPrice * (discount / 100);

      setStatus('valid');
      setCoupon({coupon: value, discount, discountPrice: finalPrice});

      return;
    }

    const allCoupons = Object.keys(discountConfig).map((key) => discountConfig[key].coupons).flat();

    if (!allCoupons.includes(value)) {
      setStatus('invalid');
      setCoupon(null);
      return;
    }

    const requestedDiscountKey = Object.keys(discountConfig)
      .find((key) => discountConfig[key].coupons.includes(value));

    const discountConfigByCoupon = discountConfig[requestedDiscountKey];

    if (discountConfigByCoupon.exactAmount && imageCount !== Number(requestedDiscountKey)) {
      setStatus('invalid');
      setCoupon(null);
      return;
    }

    if (imageCount < Number(requestedDiscountKey)) {
      setStatus('invalid');
      setCoupon(null);
      return;
    }

    const discount = discountConfigByCoupon.discount;
    const finalPrice = netPrice * (discount / 100);

    setStatus('valid');
    setCoupon({coupon: value, discount, discountPrice: finalPrice});
  };

  const handleConfirm = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      validateCoupon(value);
    }, 1500);
  };

  const handleAutoConfirm = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      validateCoupon(DEFAULT_COUPON);
    }, 1500);
  };

  const handleFocus = () => {
    setFocus(null);
  };

  const handleBlur = (e) => {
    setFocus(false);
  };

  const removeCoupon = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setCoupon(null);
      setStatus('removed');
      setValue('');
    }, 1500);
  }, [setCoupon]);

  const discount = netPrice * (selectedCoupon?.discount / 100);

  useEffect(() => {
    if (selectedCoupon) {
      setIsVisibleCoupon(true);
      setStatus('valid');
    } else if (status !== "removed" && status !== "invalid") {
      setStatus('');
    }
  }, [status, selectedCoupon]);

  return isVisibleCoupon ? (
    <div className="input-group-coupon-container">
      <div className="input-group-coupon">
        <div className="action-container">
          {loading ? (
            <Loader />
          ) : (
            <>
              {status === 'valid' ? (
                <button type="button" className=" remove-btn" onClick={removeCoupon}>
                  הסר
                </button>
              ) : value === '' && imageCount >= minImagesForAutoDiscountAvailable ? (
                <button type="button" className=" check-btn" onClick={handleAutoConfirm}>
                  <AutoLoader />
                </button>
              ) : (
                <button type="button" className=" check-btn" onClick={handleConfirm}>
                  הפעל
                </button>
              )}
            </>
          )}
        </div>
        {status === 'valid' ? (
          <div className="valid-container">
            <div className="valid-container-text">
              <div className="valid-container-text-row">
                <p className="first-text-coupon first-text">פעיל</p>
                <p className="first-text-coupon">{selectedCoupon?.coupon}</p>
              </div>
              <p className="second-text">{`${Math.ceil(discount)} שח חסכון (${selectedCoupon?.discount}% הנחה)`}</p>

            </div>
            <img
              className="valid-icon"
              src="/assets/file/images/success_icon.svg"
              alt="coupon icon"
            />
          </div>
        ) : (
          <>
            <input
              className={`coupon-input ${status === 'invalid' ? 'invalid-coupon-input' : ''}`}
              value={value}
              onChange={(e) => {
                const value = e.target.value.toUpperCase();
                setStatus('');
                setValue(value);
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={isFocus ? '' : 'יש לי כרטיס מתנה'}
              disabled={status === 'valid'}
            />

            <div className="input-group-adornment ">
              <span
                className={`coupon-input-group-text ${status === 'invalid' ? 'invalid-coupon-input-group-text' : ''
                }
              ${status === 'valid' ? 'valid-coupon-input-group-text' : ''}`}
              >
                {status === 'valid' ? (
                  <img src="/assets/file/images/success_icon.svg" alt="coupon icon" />
                ) : (
                  <>
                    <div className="coupon-divider" />
                    <img src="/assets/file/images/Coupon_icon.svg" alt="coupon icon" />
                  </>
                )}
              </span>
            </div>
          </>
        )}
      </div>
      {status === 'invalid' && (
        <div className="error-text-container">
          <p className="error-text">הקופון שהוזן לא קיים או לא בתוקף</p>
          <img src="/assets/file/images/alert-error-icon.svg" alt="coupon icon" />
        </div>
      )}
      {status === 'removed' && (
        <div className="remove-text">
          <p>הקופון הוסר</p>
        </div>
      )}
      {status !== 'removed' && status !== 'invalid' ? <div className="info-text" /> : null}
    </div>
  ) : (
    <div className="show-coupon-container">
      <button className="show-coupon-btn" onClick={() => setIsVisibleCoupon(true)}> יש לי כרטיס מתנה</button>
      <img className="show-coupon-img" src="/assets/file/images/Coupon_icon.svg" alt="coupon icon" />
    </div>
  );
};

export default InputCoupon;
