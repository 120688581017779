import React from "react";
import { useRecoilState } from "recoil";
import { modalWindows, overlayState } from "@atoms";
import './style.css';

const ImageOptionsMobile = ({ isOpen, style, options }) => {
  const [, setModals] = useRecoilState(modalWindows);
  const [, setOverlay] = useRecoilState(overlayState);

  const hideOptons = () => setModals(state => ({ ...state, imageOptions: { ...state.imageOptions, visible: false }}));

  const handleOpenEditImage = () => {
    options?.editImage();
    hideOptons();
  }

  const handleOpenDeleteImage = () => {
    options?.deleteImage();
    hideOptons();
    setOverlay(false);
  }
  
  const handleCancel = () => {
    hideOptons();
    setOverlay(false);
  }
  
  return (
    <>
    <div className="image-options" style={{ ...style }}>
      <button className="image-options__item" onClick={() => handleOpenEditImage()} style={{fontWeight: "500"}}>
      {/* <span></span> */}
      <p>עריכה</p>
      <img src="/assets/file/images/edit_image2.png" alt="edit" />
      </button>
      {(!options?.isBlob && !options?.isCropped) && (
        <button className="image-options__item" onClick={() => handleOpenDeleteImage()} style={{fontWeight: "400"}}>
        {/* <span></span> */}
        <p>מחיקת תמונה</p>
        <img src="/assets/file/images/Delete_icon2.png" alt="delete" />
        </button>
      )}
      {/* <button className="image-options__item" onClick={() => handleCancel()} style={{fontWeight: "500"}}>
      ביטול
    </button> */}
    </div>
    </>
  );
};

export default ImageOptionsMobile;
