import React from 'react';
import './style.css';
import { HowToPurchaseItem } from './HowToPurchaseItem';
import { Truck } from './truck'
import { Mouse } from './mouse'
import { Heart } from './heart'
import { Gallery } from '../../Shared/Modals/AboutUsMobile/components/HowToPurchase/gallery';
import { Arrow } from './arrow'

const HowToPurchaseItemData = [
    {
        icon: <Heart />,
        title: 'מעצבים קיר מושלם בקלות',
        description: 'המסגרות של בלנדס פשוט נדבקות לקיר, התמונות שלכם יהפכו בקלות כל קיר לקיר מלא בזכרונות נעימים בעזרת מסגרות קיר נדבקות של בלנדס.',
        number: 4
    },
    {
        icon: <Truck />,
        title: 'אשרו את כתובתכם למשלוח החבילה',
        description: 'הכניסו את פרטי המשלוח שלכם באיזור הקופה לאחר שלב אישור המסגרות, לרשותכם משלוח עד פתח הבית.',
        number: 3
    },
    {
        icon: <Mouse />,
        title: 'סמנו את סוג המסגרת על פי טעמכם',
        description: 'סמנו עם העכבר את סוג המסגרת על פי טעמכם באיזור המסגרות, לרשותכם הדמייה של המסגרות עם התמונות שבחרתם על מנת להקל עליכם בבחירת המסגרת. ',
        number: 2
    },
    {
        icon: <Gallery />,
        title: 'בחרו את התמונות שלכם',
        description: 'תרכזו את התמונות שתרצו לקבל, אל דאגה במידת הצורך אנחנו נתריע לכם מראש על כל תמונה שלא מתאימה להדפסה',
        number: 1
    }
]

export const HowToPurchase = ( ) => {
  return (
    <div className="how-to-purchase-container">
        <div dir='rtl' className='how-to-purchase-title'>
        כיצד לרכוש בבלנדס
        <div className='how-to-purchase-arrows'>
            <img src="https://res.cloudinary.com/dzb3a5brn/image/upload/v1709217279/l4d30lx3dvvu53vo2u3q.svg" alt=""/>
            <img src="https://res.cloudinary.com/dzb3a5brn/image/upload/v1709216262/b2gnct2l5suefwvo8wto.svg" alt=""/>
            <img src="https://res.cloudinary.com/dzb3a5brn/image/upload/v1709217279/l4d30lx3dvvu53vo2u3q.svg" alt=""/>
        </div>
        </div>
        <div className='how-to-purchase-items-container'>
            {HowToPurchaseItemData.map((item)=>{
                return <HowToPurchaseItem icon={item.icon} number={item.number} title={item.title}  />
                // <HowToPurchaseItem description={item.description} icon={item.icon} number={item.number} title={item.title}  />
            })}
        </div>

    </div>
  );
};
