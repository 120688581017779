const reviews = [
    {
      imageUrl: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--x9pilTOg--/c_fill,g_xy_center,h_320,q_100,w_305,x_1118,y_759/v1695230038/16774a89-98a6-4512-a740-045244818642_s61i16.webp',
      text: '"שירות מעולה 10מ10. משלוח מהיר, הגיע תוך שלושה ימים. המוצר מהמם. אזמין עוד."',
      iconUrl: '/assets/images/revgoogle.svg',
      reviewer: 'ruty_fogler'
    },
    {
      imageUrl: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--rwAPl6pN--/c_fill,g_north_west,h_320,q_100,w_305,x_0,y_0/v1690045883/2023-07-22_20-10-18_jb4pvz.webp',
      text: '"מהמםםם יצא פשוט מושלם!! הקיר מנציח רגשות וזכרונות בצורה אסתטית ומהממת!"',
      iconUrl: '/assets/images/revinstagram.svg',
      reviewer: '@elinorrahamim'
    },
    {
      imageUrl: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--IS7b98e6--/c_fill,g_center,h_320,w_305/v1683388904/IMG_1055_ojgfor.webp',
      text: '"הקיר יצא יפה מאוד, כיף לי להסתכל על המשפחה שלי והזכרונות שבנינו ביחד"',
      iconUrl: '/assets/images/revgeneral.svg',
      reviewer: 'אלברט כהן'
    },
    {
      imageUrl: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--F450AlEL--/c_fill,g_xy_center,h_320,q_100,w_305,x_780,y_817/v1710333340/IMG_3163_buefji.webp',
      text: '"ממש ממש פשוט להדביק אותן על הקיר, תלינו בחדר יצא שלמות !!!!! תודה רבה רבה"',
      iconUrl: '/assets/images/revinstagram.svg',
      reviewer: 'h_kadosh'
    },
  ];
  
  export default reviews;
  