import React from 'react';

const GenAiIcon = ({}) => {
  return (
<svg width="140" height="110" viewBox="0 0 140 110" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M61.7922 51.7194C63.0608 50.3007 65.4068 51.2886 65.2785 53.1874L64.5093 64.5701C64.47 65.1515 64.6863 65.7212 65.1016 66.1301L73.0962 74.0015C74.3847 75.2701 73.462 77.4615 71.6541 77.4263L60.4368 77.2078C59.8542 77.1964 59.2955 77.4398 58.907 77.8742L51.3021 86.3784C50.0334 87.797 47.6875 86.8092 47.8158 84.9103L48.585 73.5277C48.6243 72.9462 48.408 72.3766 47.9927 71.9677L39.998 64.0962C38.7095 62.8276 39.6323 60.6362 41.4402 60.6714L52.6574 60.89C53.2401 60.9013 53.7987 60.6579 54.1872 60.2235L61.7922 51.7194Z" fill="#087E63" />
  <path d="M92.0271 17.3056C93.1257 15.8269 95.4699 16.494 95.6254 18.3296L96.7637 31.7698C96.8129 32.3513 97.1137 32.8823 97.5872 33.2235L108.607 41.1652C110.125 42.259 109.444 44.6521 107.578 44.7829L94.0275 45.7323C93.4453 45.7731 92.91 46.0661 92.5619 46.5346L84.5177 57.3617C83.4191 58.8403 81.0749 58.1732 80.9194 56.3377L79.7811 42.8975C79.7319 42.316 79.4311 41.7849 78.9576 41.4437L67.9374 33.5021C66.4196 32.4082 67.1006 30.0152 68.9669 29.8844L82.5173 28.935C83.0995 28.8942 83.6348 28.6011 83.9829 28.1326L92.0271 17.3056Z" fill="#087E63"/>
  <path d="M59.1841 33.3973H44.9353C27.1244 33.3973 20 40.5217 20 58.3326V79.7058C20 97.5167 27.1244 104.641 44.9353 104.641H66.3085C84.1194 104.641 91.2438 97.5167 91.2438 79.7058V61.8948" stroke="#087E63" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
</svg>

  );
};

export default GenAiIcon;
