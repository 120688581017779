import React from "react";
import cn from 'classnames';
import useWindowDimensions from "@helpers/hooks/windowDemensions";
import './style.css';

const FrameSelector = ({ frameChoose, frameSelected }) => {
  
  const { width } = useWindowDimensions();

  const Frames = [
    {
      name: "classic",
      img: "assets/file/images/d1.png",
      text: "קלאסי",
    },
    {
      name: "bold",
      img: "assets/file/images/d2.png",
      text: "נועז",
      label: "פופולארי",
    },
    {
      name: "ever",
      img: "assets/file/images/d3.png",
      text: "נקי",
    },
    {
      name: "clean",
      img: "assets/file/images/d4.png",
      text: "אותנטי",
      
    },
  ];
  return (
    <div className="frame-wrapper">
          
      {Frames.map((frame) => (
        <div
          key={frame.name}
          className={cn("frame-wrapper__item", { last: frameChoose === frame.name })}
          onClick={() => frameSelected(frame.name)}
        >
          {/* Desktop */}
          {width > 767 && <>
            <img src={frame.img} className="w-100" alt="" />
            <p>{frame.text}</p>
            {frame.label && <span className="popular">{frame.label}</span>}
          </>}
          {/* Mobile */}
          {/* Mobile */}
          {width <= 767 && <>
            {frame.label && <span className="popular">{frame.label}</span>} 
            <img src={frame.img} className="w-65" alt="" />
            <p>{frame.text}</p>
          </>}
        </div>
      ))}
    </div>
  );
};

export default FrameSelector;
