import FirstOnboardScreen from "../FirstOnboardScreen";
import SecondOnboardScreen from "../SecondOnboardScreen";
import ThirdOnboardScreen from "../ThirdOnboardScreen";
import ClickOut from "../ClickOut";
import React, {useState} from "react";
import {useRecoilState} from "recoil";
import {formUploadState, modalGiftState} from "../../../Recoil/Atoms";


const OnboardScreens = () => {
    const [, setGift] = useRecoilState(modalGiftState);
    const [, setUploadState] = useRecoilState(formUploadState);

    const [show, setShow] = useState({
        showFirstScreen: false,
        showSecondScreen: true,
        showThirdScreen: false,
        showClickOut: false,
        showSkip: false,
    });

    const onboarding = localStorage.getItem('loginCompleted');

    return (parseInt(onboarding) !== 1) && (
        <div>
            {show.showFirstScreen && (
                <FirstOnboardScreen show={show.showFirstScreen}
                                    onClose={() => {
                                        setShow({ ...show, showFirstScreen: false, showSecondScreen: true});
                                    }}
                                    onClickOut={() => {
                                        setShow({...show, showClickOut: true,});
                                    }}
                />
            )}
            {show.showSecondScreen && (
                <SecondOnboardScreen show={show.showSecondScreen}
                                     firstOption={() => {
                                         setShow({ ...show, showSecondScreen: false, showThirdScreen: true});
                                         setGift("self");
                                     }}
                                     secondOption={() => {
                                         setShow({ ...show, showSecondScreen: false, showThirdScreen: true});
                                         setGift("gift");
                                     }}
                                     onClickOut={() => {
                                         setShow({...show, showClickOut: true,});
                                     }}
                                     onSkip={() => {
                                         setShow({ ...show, showSecondScreen: false, showThirdScreen: true});
                                     }}
                />
            )}
            {show.showThirdScreen && (
                <ThirdOnboardScreen show={show.showThirdScreen}
                                    onClose={() => {
                                        setShow({ ...show, showThirdScreen: false});
                                    }}
                                    onClickOut={() => {
                                        setShow({...show, showClickOut: true,});
                                    }}
                                    onSkip={() => {
                                        localStorage.setItem('loginCompleted', 1);
                                        setUploadState(true);
                                        setShow({showThirdScreen: false});
                                    }}
                />
            )}
            <ClickOut show={show.showClickOut}
                      onClose={() => {
                          setShow({ ...show, showClickOut: false,});
                      }}
                      onCloseAll={() => {
                          setUploadState(true);
                          localStorage.setItem('loginCompleted', 1);
                          setShow({showClickOut: false});
                      }}
            />
        </div>
    )
}

export default OnboardScreens;
