import React from 'react';
const Item = ({description, icon, number, title}) => {
  return (
    <div className="how-to-purchase-item">
        <div className='how-to-purchase-item-column'>
            <div>
                {icon}
            </div>
            <div className='how-to-purchase-item-title-container'>
                {title.map((text)=>{
                    return <div className='how-to-purchase-item-title'>{text}</div>
                })}
            </div>
            <div className='how-to-purchase-item-description'>
                {description}
            </div>
        </div>
        <div className='how-to-purchase-item-column-number'>
            <div className='how-to-purchase-item-number'>
                {number}
            </div>  
        </div>
    </div>
  );
};

export default Item
