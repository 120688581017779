import React from 'react'
import "./add-bw-filter.css"

const AddBWFilter = ({show, onFilterClose, onClose, isAdd}) => {
  return (
    <div className={`add-bw-filter ${show ? "show" : ""}`}>
      <div onClick={e => e.stopPropagation()} className="add-bw-filter-content">
        <div className="add-bw-filter-header">
        <p dir="rtl">
          {isAdd ? "שחור לבן על כל התמונות?" : "הסרתם פילטר צבע מהתמונה"}
        </p>
        </div>
        <div className="add-bw-filter-body">
          <p>
            {isAdd ? "?תרצו להפעיל את הפילטר על שאר התמונות" : "?תרצו להסיר את הפילטר גם משאר התמונות"}
          </p>
        </div>
        <div className="add-bw-filter-footer">
          <p className="add-bw-filter-text" onClick={onClose}>
            {isAdd ? "תצבע רק את זו" : "הסר רק מזו"}
          </p>
          <button onClick={onFilterClose} className="add-bw-filter-button-close">
            <p className="add-bw-filter-text-button">
              {isAdd ? "צבעו את כולן" : "הסירו מכולן"}
            </p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddBWFilter;