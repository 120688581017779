import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
    key: 'recoil-persist',
    storage: localStorage,
    converter: JSON
})
export const overlayState = atom({
    key: 'overlay',
    default: false,
});

export const secondOverlayState = atom({
    key: 'secondOverlay',
    default: false,
});

export const paymentMethods = atom({
    key: 'payments',
    default: []
})

export const selectedPaymentMethod = atom({
    key: 'selectedPayment',
    default: undefined,
})

export const selectedShippingAddress = atom({
    key: 'selectedAddress',
    default: undefined,
    effects_UNSTABLE: [persistAtom],
})

export const showMenu = atom({
    key: 'menu',
    default: false,
})
export const modalWindows = atom({
    key: 'modals',
    default: {
        editImage: { visible: false, data: {}},
        uploadOptions: { visible: false, data: {}},
        imageLoader: { visible: false, data: {}},
        imageOptions: { visible: false, data: {}},
        deleteConfirm: { visible: false, data: {}},
        checkout: { visible: false, data: {}},
        mobileCheckout: { visible: false, data: {}},
    }
})

export const secondaryModals = atom({
    key: "secondary-modals",
    default: {
        addCard: {visible: false, data: {}},
        addCardMobile: {visible: false, data: {}},
        selectCard: {visible: false},
        selectCardMobile: {visible: false},
        addAddress: {visible: false, data: {}},
        addAddressMobile: {visible: false, data: {}},
        errorCart: {visible: false},
        aboutUs: {visible: false},
        aboutUsMobile: {visible: false},
        whatsApp: {visible: false},
        whatsAppMobile: {visible: false},
        bitPay: {visible: false, data: {}},
        bitPayMobile: {visible: false, data: {}},
    },
});
export const imagesData = atom({
    key: 'uploadedImages',
    default: [],
})
export const sharedFunction = atom({
    key: 'sharedFunc',
    default: {},
})

export const popUpImage = atom({
    key: 'imageOnPopup',
    default: undefined,
})

export const validCoupon = atom({
    key: 'selectedCoupon',
    default: null,
})

// Accessibility statement  =>  AS
// Privacy Policy           =>  PP
// Common questions         =>  CQ
// Shipments and returns    =>  SR
export const aboutUs = atom({
    key: 'aboutUs',
    default: '',
})

export const loaderState = atom({
    key: 'loaderState',
    default: {
        showLoader: false,
        show50: false,
        show100: false,
        showText: false,
    },
});

export const loadedImagesCount = atom({
    key: 'loadedImagesCount',
    default: 0,
});

export const imagesBlobState = atom({
    key: 'imagesBlobState',
    default: [],
})

export const imageFrameState = atom({
    key: 'imageFrameState',
    default: "",
})

export const modalGiftState = atom({
    key: 'modalGiftState',
    default: "",
})

export const formUploadState = atom({
    key: 'formUploadState',
    default: false,
})

export const currentLocationState = atom({
    key: 'currentLocationState',
    default: ""
})

export const imageUploadState = atom({
    key: 'imageUploadState',
    default: false,
})

export const addressLinkState = atom({
    key: 'addressLinkState',
    default: false,
})

export const uploadPromptState = atom({
    key: 'uploadPromptState',
    default: true,
})

export const imagesLoadingState = atom({
    key: 'imagesLoadingState',
    default: false,
})

export const croppedImagesState = atom ({
    key: 'croppedImagesState',
    default: [],
})

export const shouldValidateState = atom({
    key: 'shouldValidateState',
    default: true,
})

export const bwFilterState = atom({
    key: 'bwFilterState',
    default: false,
})

export const addFilterState = atom({
    key: 'addFilterState',
    default: false,
})