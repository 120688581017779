import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './ReviewsSlider.css';

const ReviewsSlider = ({ reviews }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerStyle, setContainerStyle] = useState({});

  // Preload all review images
  useEffect(() => {
    reviews.forEach(review => {
      const img = new Image();
      img.src = review.imageUrl;
    });
  }, [reviews]);

  const getNextIndex = () => currentIndex === reviews.length - 1 ? 0 : currentIndex + 1;
  const getPrevIndex = () => currentIndex === 0 ? reviews.length - 1 : currentIndex - 1;

  const goToPrevious = () => {
    setCurrentIndex(getPrevIndex());
    setContainerStyle({
      transform: 'scale(1)',
      opacity: 0.90
    });
    setTimeout(() => setContainerStyle({ transform: 'scale(1)', opacity: 1 }), 200);
  };

  const goToNext = () => {
    setCurrentIndex(getNextIndex());
    setContainerStyle({
      transform: 'scale(1.001)',
      opacity: 0.90
    });
    setTimeout(() => setContainerStyle({ transform: 'scale(1)', opacity: 1 }), 200);
  };

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      const limitedDeltaX = Math.max(-15, Math.min(eventData.deltaX, 15));
      setContainerStyle({ transform: `translateX(${limitedDeltaX}px)` });
    },
    onSwiped: () => setContainerStyle({}),
    onSwipedLeft: goToNext,
    onSwipedRight: goToPrevious,
    trackMouse: true
  });

  return (
    <div className='reviewsmobile'>
      <div className="indicator">
        {reviews.map((_, index) => (
          <span key={index} className={currentIndex === index ? 'active' : ''} />
        ))}
      </div>
      {/* <h2>ביקורות שגרמו לנו לחייך</h2> */}
      <div className="reviews-slider" {...handlers} style={{...containerStyle, maxHeight: '800px', width: '88vw' }}>
        <div className="slider-content">
          <img src={reviews[currentIndex].imageUrl} alt="Review" loading='lazy'/>
          <p className="review-text">{reviews[currentIndex].text}</p>
          <div className="reviewer-info">
            <img src={reviews[currentIndex].iconUrl} alt="Reviewer" />
            <span>{reviews[currentIndex].reviewer}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsSlider;
