import cn from "classnames";
import React, {useRef, useState, useEffect, useCallback} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import Cropper from "react-cropper";
import {popUpImage} from "@atoms";
import {getImagesDB} from "@api";
import useWindowDimensions from "@helpers/hooks/windowDemensions";

import axios from "axios";
import "./style.css";
import {addFilterState, bwFilterState, croppedImagesState, imagesData} from "../../../Recoil/Atoms";
import HeartLoader from "../../HeartLoader";
import ImageLoader from "@shared/ImageLoader";
import AddBWFilter from "../AddBWFilter";

const ZOOM_STEP = 0.005;

const EditModal = ({handleCloseModal, modalData}) => {
  const cropperRef = useRef(null);
  const {height, width} = useWindowDimensions();
  const imageElement = cropperRef?.current;
  const cropper = imageElement?.cropper;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const localstr = localStorage.getItem("uniqueUserId");
  const imageonpopup = useRecoilValue(popUpImage);
  const setImage = useSetRecoilState(popUpImage);
  const [zoomvalue, setzoomvalue] = useState(0.0);
  const [zoomMinValue, setZoomMinValue] = useState(0.0);
  const [isLoading, setLoading] = useState(true);
  const [isInitialLoaded, setIsInitialLoaded] = useState(true);
  const isInitial = useRef(true);
  const [images] = useRecoilState(imagesData);
  const [croppedImages, setCroppedImages] = useRecoilState(croppedImagesState);
  const [isBwFilter, setIsBwFilter] = useRecoilState(bwFilterState);
  const [showAddFilter, setShowAddFilter] = useRecoilState(addFilterState);
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    const correspondingImage = images.find(image => image.publicId === imageonpopup.id);
    if (correspondingImage) {
      setImage(correspondingImage);
    }
  }, [images]);

  useEffect(() => {
    cropper?.zoomTo(+imageonpopup.zoomvalue);
    cropper?.rotate(+imageonpopup.rotate);
    setIsBwFilter(imageonpopup.bwFilter);
  }, []);

  const handleFilterUpdate = async () => {
    try {
      const localstr = localStorage.getItem("uniqueUserId");
      if(isBwFilter) {
        await axios.post(`${BASE_URL}/add-bw-filter/${localstr}`);
      } else {
        await axios.post(`${BASE_URL}/remove-bw-filter/${localstr}`);
      }

      await getImagesDB();

      setShowAddFilter(false);
    } catch (err) {
      console.log(err);
    }
  }

  const calCropData = (imageonpopup) => {
    if (imageonpopup.cropbox_data.naturalWidth) return {
      ...imageonpopup.cropbox_data,
    };
    let left = imageonpopup.cropbox_data.left;
    let top = imageonpopup.cropbox_data.top;
    const naturalHeight = parseInt(imageonpopup.imageheight);
    const naturalWidth = parseInt(imageonpopup.imagewidth);
    const croperSize = getCropperSize();
    let rate;
    if (naturalHeight <= naturalWidth) {
      rate = croperSize / naturalHeight;
    } else {
      rate = croperSize / naturalWidth;
    }
    left = left * -rate;
    top = top * -rate;
    return {
      top,
      left,
    };
  };
  const InitialSets = (target) => {
    target?.zoomTo(+imageonpopup.zoomvalue);
    setzoomvalue(+imageonpopup.zoomvalue);
    target?.rotate(+imageonpopup.rotate);
    const cropData = calCropData(imageonpopup);
    target.moveTo(cropData.left, cropData.top);
    setLoading(false);
    setIsInitialLoaded(false);
  };

  const getCropData = async () => {
    setLoading(true);
    setShowAddFilter(openFilter);
    handleCloseModal("editImage");
    setCroppedImages([
      ...croppedImages,
      imageonpopup,
    ]);
    if (typeof cropper !== "undefined") {
      var cropbox_data = cropper.getCanvasData();
      var image_data_rotation = cropper.getImageData();
      var rotate = 0;
      if (image_data_rotation.rotate === undefined) {
        rotate = 0;
      } else {
        rotate = image_data_rotation.rotate;
      }

      const aa = {
        uid: localstr,
        bwFilter: isBwFilter,
        id: imageonpopup?._id,
        // base64Image: cc,
        cropbox_data: cropbox_data,
        rotate: rotate,
        zoomvalue: (zoomvalue === 0) ? 0.01 : zoomvalue,
        defaultSize: getCropperSize(),
      };

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios.post(BASE_URL + "/cropped_img", aa, config).then((v) => {
        getImagesDB();
        const updatedArray = croppedImages.filter((img) => img !== imageonpopup);
        setCroppedImages(updatedArray);
        setLoading(false);
      });
    }
  };

  const getZoomLevel = useCallback((imageHeight, imageWidth) => {
    const maxImageDimension = Math.max(imageHeight, imageWidth);
    const zoomMin = +window.sessionStorage.getItem("minZoomLevel");
    if (maxImageDimension >= 1600) {
      return 2 * zoomMin;
    } else if (maxImageDimension >= 1400) {
      return 1.6 * zoomMin;
    } else if (maxImageDimension >= 1200) {
      return 1.4 * zoomMin;
    } else if (maxImageDimension >= 800) {
      return 1.2 * zoomMin;
    } else {
      return 0.0;
    }
  }, []);

  const handleOnZoom = useCallback((e) => {
    if (isInitial.current) {
      setZoomMinValue(+e.detail.oldRatio);
      window.sessionStorage.setItem("minZoomLevel", +e.detail.oldRatio);
      isInitial.current = false;
    } else {
      if (imageonpopup.imagewidth < 800 || imageonpopup.imageheight < 800) {
        e.preventDefault();
      }
      if (e.detail.ratio >= getZoomLevel(+imageonpopup.imageheight, +imageonpopup.imagewidth)) {
        e.preventDefault();
      } else {
        setzoomvalue(e.detail.ratio);
      }
    }
  }, [getZoomLevel, imageonpopup.imageheight, imageonpopup.imagewidth]);

  const handleZoomTo = (e) => {
    setzoomvalue(+e.target.value);
    cropper?.zoomTo(+e.target.value);
  };

  const zoomvalueplus = () => {
    if (imageonpopup.imagewidth < 800 || imageonpopup.imageheight < 800) return;
    if (zoomvalue >= getZoomLevel(+imageonpopup.imageheight, +imageonpopup.imagewidth)) {
      return;
    } else {
      setzoomvalue((zm) => zm + ZOOM_STEP);
      cropper.zoomTo((zoomvalue + ZOOM_STEP));
    }
  };

  const zoomvalueminus = () => {
    if (zoomvalue < zoomMinValue + ZOOM_STEP) {
      setzoomvalue(zoomMinValue);
      cropper.zoomTo(zoomMinValue);
    } else {
      setzoomvalue((zm) => zm - ZOOM_STEP);
      cropper.zoomTo((zoomvalue - ZOOM_STEP));
    }
  };
  const isLowImgSize = +imageonpopup.imageheight > 800 || +imageonpopup.imagewidth > 800;
  const getCropperSize = () => ({
    classic: 252,
    clean: 252,
    bold: 252,
    ever: 252,
  }[modalData.frame]);

  const el = document.querySelector(".cropper-container");

  if (el) {
    switch (modalData?.frame) {
      case "bold":
        el.style.cssText += "transform: scale(1.35) translateY(33px);";
        break;
      case "ever":
        el.style.cssText += "transform: scale(1.37) translateY(33px);";
        break;
      case "clean":
        el.style.cssText += "transform: scale(1.07) translateY(7px);";
        break;
    }
  }

  const handleFilterClick = () => {
    const imagesExceptEdited = images.filter((img) => img._id !== imageonpopup._id);

    if(!isBwFilter){
      const coloredImg = imagesExceptEdited.find((img) => img.bwFilter === false);
      if(coloredImg) {
        setOpenFilter(true);
      }
    } else {
      const bwImage = imagesExceptEdited.find((img) => img.bwFilter === true);
      if(bwImage) {
        setOpenFilter(true);
      }
    }

    setIsBwFilter(!isBwFilter);
  };

  return (
    <div id="edit-modal">
      <AddBWFilter
        show={showAddFilter}
        onFilterClose={handleFilterUpdate}
        onClose={() => {setShowAddFilter(false)}}
        isAdd={isBwFilter}
      />
      {width > 767 ?
        (<div className="edit-modal__header">
          <button disabled={!isInitialLoaded && isLoading} onClick={() => getCropData()} className="edit">
            <>
              סיום
            </>
          </button>
          <button onClick={() => handleCloseModal("editImage")} className="close">
            <img src="/assets/file/images/cross.svg" alt="close" />
          </button>
        </div>)
        : (<div className="edit-modal__header-moblow">
          <p>עריכת תמונה (21x21)</p>
        </div>)
      }
      <div className="edit-modal__content">

        {width > 767 && (
          <>
            <div className="black-white-filter" onClick={handleFilterClick}>
              <img
                src={`/assets/images/bw-filter-button${isBwFilter ? "-clicked" : ""}.svg`}
                alt="black and white filter"
              />
            </div>
          </>
        )}
        <div className="modal-title-mob">
          {isLowImgSize ? <p style={{fontWeight: "400", fontSize: "14px"}}>
          <span style={{fontSize: "18px", fontWeight: "500"}}>הזזה והגדלה</span>
          <br/>
              {/* <img src="/assets/images/editpinch.svg" /> */}
              צבטו כדי להגדיל ולהזיז
            </p> :
            <p>
              <img src="/assets/images/editmove.svg" />
          ניתן להזיז את התמונה בתוך המסגרת
            </p>}
        </div>
        <div className={`${modalData?.frame} cropper-frame ${isBwFilter ? "grayscale-filter" : ""}`}
             style={{marginTop: "24px", boxShadow: "0 3px 7px rgb(0 0 0 / 5%)"}}>
          {imageonpopup._id ? (
            <>
              {isInitialLoaded && (
                <>
                  <div id={`${width > 767 ? "deskedit-in-frame-loader" : "mobiledit-in-frame-loader"}`} />
                </>
              )}
              <Cropper
                ref={cropperRef}
                style={{
                  height: getCropperSize(),
                  width: getCropperSize(),
                  transform: "rotationimage",
                  margin: "0 auto",
                }}
                zoomable
                initialAspectRatio={1}
                preview=".img-preview"
                checkCrossOrigin={true}
                src={`${imageonpopup.image}`}
                viewMode={3}
                autoCropArea={1}
                guides={false}
                toggleDragModeOnDblclick
                ready={(e) => {
                  InitialSets(e.target.cropper);
                }}
                dragMode="move"
                background
                responsive
                checkOrientation={false}
                zoom={handleOnZoom}
                center={false}
              />
            </>
          ) : (
            <>
              <div className={`album-item-wrapper`}
                   style={{margin: "auto", width: "100%", height: "100%", filter: "none"}}>
                <ImageLoader
                  loadingBlob={imageonpopup.url}
                  loading={modalData.imagesBlob.includes(imageonpopup)}
                  src={imageonpopup.url ? imageonpopup.url : imageonpopup.image}
                  loaderClassName={cn(`img-con blurred-loader-image`)}
                  className={cn(`img-con edit blurred-loader-image edit`)}
                  alt="icon"
                  shadow={true}
                  style={{padding: "0"}}
                />
              </div>
            </>
          )}
        </div>
        {isLowImgSize ? <div className="rang_container">
          <div
            onClick={() => {
              zoomvalueminus();
            }}
          />
          <input
            type="range"
            min={`${zoomMinValue}`}
            max={`${getZoomLevel(+imageonpopup.imageheight, +imageonpopup.imagewidth)}`}
            value={zoomvalue}
            onChange={handleZoomTo}
            step={`${ZOOM_STEP}`}
            id="zoomer"
            className="slider"
          />
          <img
            src="/assets/file/images/plus_icon.svg"
            onClick={() => {
              zoomvalueplus();
            }}
            alt="plus-icon"
          />
        </div> : null}

        <div className="modal-title-desk">
          {isLowImgSize ?
            <p style={{fontWeight: "500", fontSize: "14px"}}>
              הזיזו והגדילו בעזרת העכבר
            </p> :
            <p>
ניתן להזיז את התמונה בתוך המסגרת בעזרת העכבר
            </p>}
        </div>
        {width < 767 && (
          <>
          <div className="black-white-filter" onClick={handleFilterClick}> 
            <img src={`/assets/images/bw-filter-button${isBwFilter ? "-clicked" : ""}.svg`} alt="תמונות בשחור לבן" /> 
            <span className="text-2">חדש!</span> 
            <span className="text-1">צבעו את התמונה בשחור לבן</span> 
          </div>
          </>
        )}
        {width <= 767 && (
          <button className="mobile-save-button" onClick={() => getCropData()}>
            {isLoading && !isInitialLoaded ? (
              <>
                <div className="ldio-kv0ui0pfesk-edit" />
              </>
            ) : (
              <>
                אישור
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default EditModal;
