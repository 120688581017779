export const CommentIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.4073 11.1489C38.5541 13.5249 35.9209 14.3661 34.5259 13.0278C33.1308 11.6894 33.5022 8.67828 35.3554 6.30223C37.2086 3.92617 39.8418 3.08496 41.2369 4.42333C42.6319 5.7617 42.2605 8.77283 40.4073 11.1489Z" fill="#8CE9B5"/>
            <path d="M44 16V26C44 34 40 38 32 38H31C30.38 38 29.78 38.3 29.4 38.8L26.4 42.8C25.08 44.56 22.92 44.56 21.6 42.8L18.6 38.8C18.28 38.36 17.54 38 17 38H16C8 38 4 36 4 26V16C4 8 8 4 16 4H24" stroke="#292D32" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M30.4 9.24004C29.74 7.26004 30.52 4.82004 32.68 4.14004C33.8 3.80004 35.2 4.08004 36 5.14004C36.76 4.04004 38.2 3.80004 39.32 4.14004C41.48 4.80004 42.26 7.26004 41.6 9.24004C40.58 12.38 37 14 36 14C35 14 31.46 12.4 30.4 9.24004Z" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M31.9932 22H32.0111" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.991 22H24.0089" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.989 22H16.007" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
