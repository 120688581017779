import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import {useRecoilState, useRecoilValue} from 'recoil'
import { overlayState, showMenu, aboutUs, currentLocationState } from '@atoms';
import { useSecondModal } from "@helpers/hooks/useSecondModal";
import useWindowDimensions from "@helpers/hooks/windowDemensions";

const Header = () => {
  const currentLocation = useRecoilValue(currentLocationState);
  const [, setOverlay] = useRecoilState(overlayState);
  const [menuShow, setMenuShow] = useRecoilState(showMenu);
  const [, setAboutUs] = useRecoilState(aboutUs);
  const modal = useSecondModal();
  const { width } = useWindowDimensions();

  const menushow = () => {
    setMenuShow(true);
    setOverlay(true);
  };
  setTimeout(() => {
  }, 1000);

  const handelHeaderLinkToHome = () => {
    window.location.href = '/';
  };
  
  const handleAboutUsModal = (type) => {
    setAboutUs(type)
    if (width > 768) {
      modal('open', 'aboutUs')
    } else {
      modal('open', 'aboutUsMobile');
    }
  }

  return (
    <>
      <React.Fragment>
        <link rel="stylesheet" href="assets/css/style.css"></link>
          <>
          <header className="site-header fixed-top" style={{ zIndex: 98 }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-2 col-md-4 order-md-1 text-md-left text-right">
                  { width > 767 ? (
                      <img
                          class="whatsappimgdesk"
                          src="/assets/images/wtsap.svg"
                          alt=""
                          onClick={() => {
                            modal('open', 'whatsApp');
                          }}
                          style={{cursor: "pointer"}}
                      />
                  ) : (
                      <></>
                  )}
                  { width < 767 && currentLocation !== "/review-your-images" ? (
                      <img
                          class="whatsappimgmobile"
                          src="/assets/images/wtsap.svg"
                          alt=""
                          onClick={() => {
                            modal('open', 'whatsAppMobile');
                          }}
                          style={{cursor: "pointer"}}
                      />
                  ) : (
                      <></>
                  )}
                </div>

                <div className="col-8 col-md-4 text-center text-md-center order-md-2">
                    { width > 767 ? (
                        <img src="/assets/images/stk_logo.svg" alt="" />
                    ) : (
                        <img src="/assets/images/stk_logo.svg" alt="" />
                    )}
                </div>
                <div className="col-2 col-md-4 text-right order-md-3">
                  { width > 767 ? (
                      <img
                          src="/assets/images/bar.svg"
                          alt=""
                          id="menu"
                          onClick={() => {
                            menushow();
                          }}
                      />
                  ) : currentLocation !== "/review-your-images" ? (
                      <img
                          src="/assets/images/bar.svg"
                          alt=""
                          id="menu"
                          onClick={() => {
                            menushow();
                          }}
                      />
                  ) : (
                      <></>
                  )}
                </div>
              </div>
            </div>
          </header>
          <div className="menu" style={{ right: menuShow ? '0' : '-100%' }}>
            <div className="menu-head text-center">
              <img src="/assets/images/stk_logo.svg" alt="בלנדס"/>
           </div>
            <div className="menu-items">
            <ul style={{ display: "grid" }}>
              <li style={{marginBottom: "0px"}}>
                <span onClick={() => {
                            modal('open', 'whatsApp');
                          }}>דברו איתנו בוואצאפ</span>
                <img src="/assets/images/inmenuwtsap.png" alt="" />
              </li>
              <li style={{marginBottom: "0px"}} onClick={() => handleAboutUsModal('CQ')}>
                <span>שאלות נפוצות</span>
                <img src="/assets/images/inmenufaq.png" alt="" />
              </li>
              <li class="in-menu-howto" style={{marginBottom: "0px"}} onClick={() => handleAboutUsModal('HT')}>
                <span>כיצד לרכוש בבלנדס</span>
                <img src="/assets/images/inmenuhowto.png" alt="" />
              </li>
            </ul>
            </div>
            <div className="in-menu-button">
            <NavLink to='upload-your-image'>
              <button onClick={{ right: menuShow ? '0' : '-100%' }}>עצבו קיר משלכם</button>
              </NavLink>
            </div>
            <div className="in-menu-social">
              <p>
                עקבו אחרינו
              </p>
             </div>
              <div className="in-menu-social-icons">
                <a href="https://www.facebook.com/profile.php?id=100086212847385" target="_blank" rel="noreferrer">
                  <img src="/assets/images/inmenufacebook.png" alt="הפייסבוק של בלנדס"/>
                </a>
                
                <a href="https://www.instagram.com/blends_il/" target="_blank" rel="noreferrer">
                  <img src="/assets/images/inmenuinstagram.png" alt="האינסטגרם של בלנדס"/>
                </a>
                
                <a href="https://www.tiktok.com/@blends_il" target="_blank" rel="noreferrer">
                  <img src="/assets/images/inmenutiktok.png" alt="הטיקטוק של בלנדס"/>
                </a>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "20.5px",
                textAlign: "center",
                width: "360px",
                paddingRight: "5.5em"
              }}
            >
              <span style={{fontSize: "14px", color: "#C8C8C8", fontWeight: "300", lineHeight: "36px", textDecorationLine: "underline"}} onClick={() => handleAboutUsModal('AS')}>הצהרת נגישות</span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{fontSize: "14px", color: "#C8C8C8", fontWeight: "300", lineHeight: "36px", textDecorationLine: "underline"}} onClick={() => handleAboutUsModal('PP')}>מדיניות פרטיות</span>
            </div>
          </div>
          </>
      </React.Fragment>
    </>
  );
}

export default Header;
