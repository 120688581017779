import React, { useRef } from 'react';
import './ContentComponent.css'

const ContentComponent = () => {
    const videoRef = useRef(null);

return (
<div className="content">
    <video
        className='content-video'
        src="/assets/file/videos/Main.mp4"
        autoPlay
        loop
        playsInline
        muted
        id="mobile_home_video"
        ref={videoRef}>
        <source type="video/webm" src="https://res.cloudinary.com/dzb3a5brn/video/upload/s--uGdnsHeV--/q_100/v1707831030/Main_cjf0q7.webm"/>
        <source type="video/mp4" src="/assets/file/videos/Main.mp4"/>
    </video>
    <img src='/assets/images/mainoverlay.png' alt="תמונות בהדבקה" />
</div>

    );
};

export default ContentComponent;
