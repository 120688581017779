const products = [
    {
      id: 1,
      name: 'Lively Memories',
      displayImage: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--TwXc2fgk--/q_100/v1706954281/living-room1.11.01-display_rvhxn6.webp',
      dimensionImage: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--qyd9bqz2--/q_100/v1706954282/living-room1.11.01_isqnuw.webp',
      price: '630',
      discountedPrice: '466'
    },
    {
      id: 2,
      name: 'Picnic',
      displayImage: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--IlkN4FfX--/q_100/v1706954274/dining-bw-display_p8wq2p.webp',
      dimensionImage: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--_oLPFJFI--/q_100/v1706954273/dining-bw-dim_w9dfjb.webp',
      price: '810',
      discountedPrice: '599'
    },
    {
      id: 3,
      name: 'Harmony',
      displayImage: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--V_UBNxgE--/q_100/v1706954284/living-room2.11.01-display_kni8hg.webp',
      dimensionImage: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--d5MJzYLA--/q_100/v1706954286/living-room2.11.01_ird77l.webp',
      price: '675',
      discountedPrice: '499'
    },
    {
      id: 4,
      name: 'Path of Happiness',
      displayImage: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--BsW46xqF--/q_100/v1706954273/hallway1.2023.11.01-display_hmqt5k.webp',
      dimensionImage: 'https://res.cloudinary.com/dzb3a5brn/image/upload/s--dEsKJG5E--/q_100/v1706954273/hallway1.2023.11.01_dkmvke.webp',
      price: '675',
      discountedPrice: '499'
    },
  ];
  
  export default products;
  