import React from "react";

const HeartLoader = ({isLoading, showText, mobileEdit= false, background = "none"}) => {
  if (!isLoading) return <></>
  return (
      <>
        <div
            className="modal loaderbg"
            id="mainImageLoaderModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "flex", backgroundColor: background }}
        >
          <div className="modal-dialog review-image-loader" role="document">
            <div className="loadingio-spinner-heart-btbrqan8295">
                {!mobileEdit ? (
                    <>
                        <div className="ldio-kv0ui0pfesk">
                            <div>
                                <div></div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                    </>
                )}
            </div>
            {showText && (
                <p className={"under-loader-text"}>טוען..</p>
            )}
          </div>
        </div>
      </>
  )
}

export default HeartLoader;
