import React, {useEffect, useRef, useState} from 'react';
import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import SliderHome from '../Partials/SliderHome';
import useWindowDimensions from '@helpers/hooks/windowDemensions';
import './home.css';
import GalleryComponent from './GalleryComponent';
import { HeroSection } from './HeroSection/HeroSection';
import { UseRefPlayer } from './UseRefPlayer';
import { BenefitsMobile } from './Benefits/BenefitsMobile';
import { HowToPurchase } from './HowToPurchase/HowToPurchase';
import ContentComponent from './ContentComponent';
import ProductListing from './GenerativeComponent/ProductListing';
import ReviewsSlider from './ReviewsMobile/ReviewsSlider'
import reviews from './ReviewsMobile/reviewsData'; 
import {CouponSection} from "../CouponSection/CouponSections";
import 'bootstrap/dist/css/bootstrap.min.css';
import { VideoPlayer } from './VideoPlayer'
import GoogleReview from './GoogleReviews';
import Valentines from './Valentines';
import Footermob from './Footermob';

const Home = ({ existData }) => {
  const videoRef = useRef();
  const { width } = useWindowDimensions();
  useEffect(() => {
    setTimeout(() => {
      videoRef.current?.play();
    }, 1000);
  }, []);

  const renderDesktop = () => (
    <>
      <section className="top-nav-spacing">
      </section>
      {/* <div className='standwithisrael'>
        <img src="assets/images/israel.png" alt="ביחד ננצח"/>
        <p>מאוחדים ביחד
        </p>
      </div> */}
      <section className="banner">
        <div className="container hero-container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="ban-txt row">
                <div className="ban-txt-wrap order-md-1 order-3 col-12">
                  <h2 style={{fontWeight: 700, fontSize: "52px", marginBottom: '24px', marginTop: '72px', width: '100%', lineHeight: '50px'}}>תהפכו את הקיר שלכם
                  <br />
                  לסיפור עם מסגרות שנדבקות לקיר
                  </h2>
                  <p style={{color: "#727272", fontSize: '20px', marginBottom: '24px', lineHeight: '24px'}}>
                    התמונות שתבחרו יודפסו באיכות גבוהה,
                    <br />
                    ימוסגרו על פי בחירתכם ויישלחו אליכם עד פתח הבית
                  </p>
                </div>
                <div className="col-12 order-md-2 order-3 text-right text-md-right" style={{filter: "drop-shadow(0px 2px 2px rgba(8, 126, 99, 0.25))", borderRadius: '10px'}}>
                  {existData || localStorage.getItem('dataExist') == 1 ? (
                    <NavLink to="/review-your-images" className="site-btn gg1">
                      המשיכו לעצב
                    </NavLink>
                  ) : (
                    <NavLink to="/upload-your-image" className="site-btn gg1">
                      בואו נתחיל
                    </NavLink>
                  )}
                  
                  {existData || localStorage.getItem('dataExist') == 1 ? (
                    <></>
                  ) : (
                    <div className='desk-text-under-cta'>השלב הבא: מעצבים קיר משלכם</div>
                  )}
                  

                  <NavLink
                    to="/upload-your-image"
                    className="site-btn kk forhover"
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      padding: '10px 10px !important',
                      margin: '20px auto',
                    }}
                  >
                    בחירת תמונות להדפסה
                  </NavLink>
                </div>
                <div className="sub order-md-3 order-2 col-12">
                  <img
                    src="/assets/file/images/NailsHomepage.svg"
                    alt=""
                    className="xp"
                  />
                  <p>משלוחים עד פתח הדלת</p>
                  <img
                    src="/assets/images/shipping.svg"
                    alt=""
                    className="xy"
                    style={{ width: '43px', height: '38x' }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2 pl-none header-image-wrapper">
              <div className="ban-img">
                <img
                  src="https://res.cloudinary.com/dzb3a5brn/image/upload/s--lwLQ67fU--/q_100/v1699536460/DesktopHomepage8_ogrl8e.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="offer">
        {/* <div className="whyblends">
          <h1 style={{fontSize: '36px', fontWeight: 700}}>למה בלנדס?</h1>
          <br />
          <p style={{color: '#727272', fontSize: '18px'}}>
            המסגרות של בלנדס מוסיפות חיים לכל בית או משרד, בעזרתן כל קיר הופך לסיפור
            מרגש, אנחנו נותנים דגש לכל תמונה שיוצאת מהמפעל שלנו
            ודואגים לאיכות מירבית.
          </p>
        </div> */}
        <div className="container offer_container">
          <div className="row benefits">
            <div className="benefits__item">
              <img src="/assets/images/quality.svg" alt="" />
              <div className="ofr-txt">
                <h4>100% איכות</h4>
                <p>
                  ההדפסה איכותית ונשמרת לאורך שנים
                  <br />
                  אנחנו בטוחים שתאהבו את התמונות
                </p>
              </div>
            </div>
            <div className="benefits__item">
              <img src="/assets/images/wayoflove.svg" alt="" />
              <div className="ofr-txt">
                <h4>דרך נהדרת לאהוב</h4>
                <p>
                  תמונות קיר הן מתנה נפלאה לתת
                  <br />
                  לעצמכם או לאהובים שלכם
                </p>
              </div>
            </div>
            <div className="benefits__item">
              <img src="/assets/images/nonails.svg" alt="" />
              <div className="ofr-txt">
                <h4>אין צורך במסמרים</h4>
                <p>
                  המסגרות פשוט נדבקות לקיר
                  <br />
                  כך שניתן לעצב את הקיר בקלות ללא מאמץ
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about">
        <div className="container">
          <div className="about__wrapper">
            <div className="about__wrapper--image">
              <video
                style={{
                  width: '534px',
                  height: '402px',
                  borderRadius: '15px',
                }}
                autoPlay={'autoplay'}
                loop
                muted
                playsInline
              >
                <source
                  type="video/mp4"
                  src="/assets/file/videos/HomepageVideoNew.mp4"
                />

                <source
                  type="video/webm"
                  src="https://res.cloudinary.com/dzb3a5brn/video/upload/s--XH8GDr6b--/vc_auto/v1683386316/HomepageVideoNew_rc6vmu.webm"
                />
              </video>
            </div>
            <div className="about__wrapper--content">
              <h4 style={{fontSize: '36px', fontWeight: 700}}>זה ממש פשוט להדביק אותן</h4>
              <p style={{color: '#727272', fontSize: '18px'}}>
                ממש כיף לנצור רגעים נהדרים מהחיים ולהוסיף חום לבית עם התמונות של
                היקרים שלכם,
                <br />
                ההתקנה פשוטה, תליית המסגרות נעשית באמצעות הדבקה על הקיר וגם
                ההסרה מתבצעת פשוט ובקלות ללא נזק לקיר.
              </p>
            </div>
          </div>
        </div>
      </section>
      <HowToPurchase />
            <section className="slider-section">
        <div className="slider-section--wrapper">
          <div style={{ textAlign: 'center' }}>
            <h1 style={{fontSize: '36px', fontWeight: 700}}>
                תמונות שגרמו לנו לחייך
            </h1>
            <p
              style={{ fontSize: '18px', color: '#727272', marginTop: '10px', marginBottom: '30px' }}
            >הנה כמה ביקורות שקיבלנו מלקוחות מהזמן האחרון
            </p>
          </div>
          <div className="container homepage-container">
            <div className="swiper_left"></div>
            <div className="swiper_right"></div>
            <div></div>
            <SliderHome />
          </div>
          <div className='google-container'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginTop: '16px',
              marginBottom: '8px',
              gap: '1em',
            }}
          >
          <img src="/assets/images/google.svg" alt="" class="googlehp"
          style={{
            width: '3.3em',
          }}
          />
          <img src="/assets/images/stars.svg" alt="" class="starshp"
            style={{
            width: '8.7em',
          }}
          />
          <p class="googlereviewhp"
            style={{
              fontFamily: 'Rubik',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '20px',
            }}>4.9</p>

          </div>
          <div className='google-reviews-text'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontStyle: 'italic',
          }}
          >
            <p>מתוך אלפי תמונות של לקוחות מרוצים</p>
          </div>
        </div>
      </section>
      <div className='desk-bottom-cta'>
      {existData ? (
              <NavLink
                to="/review-your-images"
                className="site-btn gg1"
                style={{ backgroundColor: '#087E63' }}
              >
                המשיכו לעצב
              </NavLink>
            ) : (
              <NavLink
                to="/upload-your-image"
                className="site-btn gg1"
                style={{ backgroundColor: '#087E63' }}
              >
                {/* <div className='cta-arrow-container'>
                  <ArrowIcon arrHeight={55} arrWidth={60} />
                  <span dir='rtl' className='cta-arrow-text'>מתחילים מכאן</span>
                </div> */}
                צרו קיר משלכם
              </NavLink>
            )}
      </div>
    </>
  );

  const renderMobile = () => (
    <>
      <section className="top-nav-spacing">
         {/* <CouponSection /> */}
      </section>
      {/* <div className='standwithisrael'>
        <img src="assets/images/israel.png" alt="ביחד ננצח"/>
        <p>מערך המשלוחים פעיל, ישראל חזקה ביחד.</p>
      </div> */}
        <ContentComponent />
        <HeroSection />
        {/* <Valentines /> */}
        {/* <GalleryComponent/> */}
        <ProductListing/>
        <ReviewsSlider reviews={reviews} />
        <VideoPlayer/>
        <GoogleReview />
        <Footermob />
      {/* <div id="offer">

        <div className="additional-block">
            <div className="additional-block-first">
            <div className="additional-block-first-photo">
                    <img
                        src="/assets/images/3d-frame-low-shade.png"
                        alt="3d-frame"
                    />
                </div>
                <div className="additional-block-text">
                  
                    <p className="additional-block-first-text">מסגרות קסומות<br/>שנדבקות לקיר</p>
                    <p className="additional-block-second-text">גודלן 21 על 21 ס”מ</p>
                </div>
                
            </div>
            <div className="additional-block-second">
                <p style={{fontWeight: 400, fontSize: '14.4px'}}>
                    אפשר להסיר ממקום אחד ולהדביק במקום אחר ללא סימנים על הקיר.
                </p>
                <img
                    src="/assets/images/reuseablem1.svg"
                    alt="תמונות בהדבקה על הקיר ללא מסמרים"
                />
            </div>
            

        </div>
        <hr className="grey-line"></hr>
      </div> */}
      {/* <hr className="grey-line"></hr>
      <div className="mobile-homepage-lowmid-button">
      {existData || localStorage.getItem('dataExist') == 1 ? (
            <NavLink to="/review-your-images">
                <button>בואו נמשיך מאיפה שעצרתם</button>
            </NavLink>
            ) : (
              <NavLink to="/upload-your-image">
                <button>בואו נתחיל עם תמונות משלכם</button>
            </NavLink>
            )
          }
      </div> */}

    </>
  );
  const renderHomePage = useCallback(() => {
    return width >= 767 ? renderDesktop() : renderMobile();
  }, [width, existData]);

  return <>{renderHomePage()}</>;
};
export default Home;
