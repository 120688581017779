import React from 'react';
import  Item from './Item';
import { Truck } from './truck'
import { Mouse } from './mouse'
import { Heart } from './heart'
import { Gallery } from './gallery';

const HowToPurchaseItemData = [
    {
        icon: <Heart />,
        title: ['מעצבים את הקיר',' שלכם'],
        description: 'תנו לדמיון שלכם להוביל אתכם ועצבו את הקיר המושלם שמספר את סיפור המשפחה שלכם.',
        number: 4
    },
    {
        icon: <Truck />,
        title: ['אשרו את כתובתכם',' למשלוח החבילה'],
        description: 'הכניסו את פרטי המשלוח שלכם באיזור הקופה לאחר שלב אישור המסגרות, לרשותכם משלוח עד פתח הבית.',
        number: 3
    },
    {
        icon: <Mouse />,
        title: ['סמנו את סוג ','המסגרת לטעמכם'],
        description: 'סמנו עם העכבר את סוג המסגרת על פי טעמכם באיזור המסגרות, לרשותכם הדמייה של המסגרות עם התמונות שבחרתם על מנת להקל עליכם בבחירת המסגרת. ',
        number: 2
    },
    {
        icon: <Gallery />,
        title: ['בחרו את', 'התמונות'],
        description: 'תרכזו את התמונות שתרצו לקבל, אל דאגה במידת הצורך אנחנו נתריע לכם מראש על כל תמונה שלא מתאימה להדפסה',
        number: 1
    }
]

const HowToPurchase = ( ) => {
  return (
    <div className="how-to-purchase-container">
        <div className='how-to-purchase-items-container'>
            {HowToPurchaseItemData.map((item)=>{
                return <Item description={item.description} icon={item.icon} number={item.number} title={item.title}  />
            })}
        </div>

    </div>
  );
};

export default HowToPurchase
