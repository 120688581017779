import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import useWindowDimensions from "@helpers/hooks/windowDemensions"; // Ensure this hook is correctly imported
import { useSecondModal } from "@helpers/hooks/useSecondModal";
import { useRecoilState } from 'recoil';
import { aboutUs } from '@atoms';
import './Footermob.css';

const ExpandableRow = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`qa-row ${isOpen ? 'open' : ''}`}>
      <div className="question" onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className="icon">{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div className="answer">{answer}</div>}
    </div>
  );
};

const Footermob = () => {
  const { width } = useWindowDimensions();
  const modal = useSecondModal();
  const [, setAboutUs] = useRecoilState(aboutUs);

  const handleAboutUsModal = (type) => {
    if (width <= 767) {
      setAboutUs(type);
      modal('open', 'aboutUsMobile');
    }
  };

  return (
    <div className='footer-mob-wrap-bg'>
      {/* Payment Options Image */}
      {/* <div className="payment-options">
        <img src="/assets/images/blendsf.svg" alt="Payment Options" />
      </div> */}
    <div className="footer-mob">
      {/* Q&A Section */}
      <div className="qa-section">
        <ExpandableRow 
          question="אפשר להדביק בלנדס על כל סוגי הקירות?"
          answer="ניתן להדביק ללא חשש את מסגרות בלנדס כמעט על כל סוגי הקירות! וגם ניתן להסיר אותן מהקיר ולהעביר לקיר אחר, מומלץ שהקיר יהיה נקי, אחיד ויבש לפני ההדבקה. עדיף שלא להדביק על סוגי קירות שבהם יש נקודות טייח בולטות, תבניות שליכטה (כגון תבניות גשם, טיפות, השפרצות וכד’) או חוסר עקביות שטחית."
        />
        <ExpandableRow 
          question="מה הם הגדלים של המסגרות"
          answer="הגודל של כל המסגרות הוא ריבוע בגודל 21 על 21 ככה שהאפשרויות לבניית צורות וסיפורים על הקיר הן על פי פרי הדמיון :)"
        />
        <ExpandableRow 
          question="זמני הגעת החבילה"
          answer="בבלנדס אנחנו מחויבים לדאוג לשביעות רצונכם המירבית, ולכן מרגע ביצוע ההזמנה החבילה תגיע לרוב בתוך אותו שבוע או בין 2-4 ימי עסקים, לישובים מרוחקים 3-6 ימי עסקים נוספים, רשימת היישובים מתעדכנת מעת לעת והיא מתפרסמת באתר האינטרנט של דואר ישראל. ט.ל.ח."
        />
        <div className="qa-row">
        <span className="icon">&lt;</span>
          <NavLink to="#" onClick={() => handleAboutUsModal('CQ')} style={{ color: "#657571" }}>צפו בכל השאלות בדף השאלות והתשובות</NavLink>
        </div>
        <div className="qa-row">
        <span className="icon">&lt;</span>
        <NavLink to="/upload-your-image" style={{ color: "#657571" }}>צרו קיר מהתמונות שלכם</NavLink>
        </div>
      </div>

      {/* Titles and Links Section */}
      <div className="titles-links-section">
        {/* Social Networks */}
        <div className="social-networks">
          <h3>רוצים לעקוב?</h3>
          <NavLink to="/instagram">
            
            <img src="/assets/images/instagramf.svg" alt="Instagram" />
            אינסטגרם
          </NavLink>
          <NavLink to="/facebook">
            
            <img src="/assets/images/facebookf.svg" alt="Facebook" />
            פייסבוק
          </NavLink>
          <NavLink to="#" onClick={() => { modal('open', 'whatsAppMobile'); }}>
            <img src="/assets/images/whatsappf.svg" alt="Contact" />
            צור קשר
          </NavLink>
        </div>

        {/* Site Policies */}
        <div className="site-policies">
          <h3>תקנוני האתר</h3>
          <NavLink to="#" onClick={() => handleAboutUsModal('AS')}>הצהרת נגישות</NavLink>
          <NavLink to="#" onClick={() => handleAboutUsModal('PP')}>מדיניות פרטיות</NavLink>
          <NavLink to="#" onClick={() => handleAboutUsModal('SR')}>משלוחים והחזרות</NavLink>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="copyright-section" style={{ paddingBottom: '90px' }}>
        <p>© Blends 2021-2024</p>
      </div>
    </div>
    </div>
  );
};

export default Footermob;
