import useWindowDimensions from "@helpers/hooks/windowDemensions";
export const Heart = () => {
  const { height, width } = useWindowDimensions();
  const isMobile = width < 767
    return (
    <svg width={isMobile ? 30 : 50} height={isMobile ? 30 : 50} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M55.5159 31C56.3426 28.365 56.8334 25.5233 56.8334 22.4492C56.8334 14.4667 50.4009 8.00827 42.4701 8.00827C37.7684 8.00827 33.6092 10.2817 31.0001 13.7951C28.3909 10.2817 24.2059 8.00827 19.5301 8.00827C11.5992 8.00827 5.16675 14.4667 5.16675 22.4492C5.16675 40.5325 21.9067 51.2017 29.3984 53.785C29.8376 53.94 30.4059 54.0174 31.0001 54.0174" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M36.1665 41.3333C36.1665 48.4744 41.9421 54.25 49.0832 54.25L47.1457 51.0208" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.8332 46.5C56.8332 39.3589 51.0576 33.5833 43.9165 33.5833L45.854 36.8125" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}
