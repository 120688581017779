import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import uniqid from "uniqid";
import { uploadSingleImage, uploadMultipleImages, getImagesDB } from '@api';
import { useModal } from "@helpers/hooks/useModal";
import {
  imageCountState,
} from "@atoms/priceCalc";
import {useRecoilState, useRecoilValue} from "recoil";
import "./style.css";
import OnboardScreens from "../../Shared/Modals/OnboardScreens";
import {formUploadState, uploadPromptState} from "../../Recoil/Atoms";
import UploadPrompt from "../../Shared/Modals/UploadPrompt";

const UploadImage = () => {
  const imagecount = useRecoilValue(imageCountState);
  const [uploadState,] = useRecoilState(formUploadState);
  const [isPrompt, setPrompt] = useRecoilState(uploadPromptState);

  const [deskbody_opcy, setdeskbody_opcy] = useState();
  const [showSpinner, setshowSpinner] = useState(false);

  let history = useHistory();
  const modal = useModal();
  const onboarding = localStorage.getItem('loginCompleted');

  useEffect(() => {
    // Check if the device is mobile
    const isMobile = window.innerWidth <= 767;

    if (isMobile) {
      // Disable scrolling on mobile
      document.body.classList.add('no-scroll');
    }

    return () => {
      if (isMobile) {
        // Re-enable scrolling when the component is unmounted
        document.body.classList.remove('no-scroll');
      }
    };
  }, []);
  
  useEffect(() => {
    setshowSpinner(true);
    window.scrollTo(0, 0);
    async function initiateData() {
      await getImagesDB();
      setshowSpinner(false);
    }
    initiateData();
  }, []);

  if(localStorage.getItem("dataExist") == 1){
    history.push("/review-your-images");
  }

  const opcy_desh_click = () => {
    setdeskbody_opcy("none");
  };

  const upload = (e) => {
    if (e.type === "click") {
      openUploadMenu();
      return;
    } else {
      if (imagecount + e.target.files.length > 30) {
        alert("צרו קשר על מנת להעלות יותר מ30 תמונות");
        modal('hide', 'uploadOptions');
        return;
      }
      setshowSpinner(true);
      const uid = uniqid();
      const formdata = new FormData();
      localStorage.setItem("uniqueUserId", uid);
      if (e.target.files.length === 1) {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        img.onload = function () {
          formdata.append("image", e.target.files[0]);
          formdata.append("uid", uid);
          formdata.append("imagewidth", img.width);
          formdata.append("imageheight", img.height);
          formdata.append("imageext", 0);

          uploadSingleImage(formdata)
            .then(() => {
              setshowSpinner(false);
              localStorage.setItem('dataExist', 1);
              history.push("/review-your-images");
            })
        };
      } else {
        for (let i = 0; i < e.target.files.length; i++) {
          let files = e.target.files[i];
          let img = document.createElement("img");
          img.src = URL.createObjectURL(files);
          img.onload = function () {
            
          };
          formdata.append("imagewidth", img.width);
          formdata.append("imageheight", img.height);
          formdata.append("imageext", 0);
          formdata.append("image", files);
          formdata.append("uid", uid);
        }

        uploadMultipleImages()
          .then(() => {
            localStorage.setItem('dataExist', 1);
            history.push("/review-your-images");
          })
      }
    }
  };

  const openUploadMenu = () => {
    modal("open", "uploadOptions", {}, 400);
  };

  return (
    <>
      {(uploadState || parseInt(onboarding) === 1) && (
        <UploadPrompt show={isPrompt}/>
      )}
      <div
        className="body_opcyyy"
        style={{ display: deskbody_opcy }}
        onClick={() => {
          opcy_desh_click();
        }}
      ></div>
      <div className={`desk-up`}>
        {!showSpinner && (
          <>
            <OnboardScreens />
            {(uploadState || parseInt(onboarding) === 1) && (
                <div className="form-upload">
                  <div className="form-area-upload">
                  <img src="https://www.facebook.com/tr?id=266939557094379&ev=OnboardingSuccess" height="1" width="1" alt=""/>
                  <h2>התחילו לעצב</h2>
                    <input
                        type="file"
                        accept="image/*"
                        className="file-input finputupload fpu"
                        multiple
                        name="file"
                        onChange={(e) => upload(e)}
                        onClick={(e) => { upload(e); e.preventDefault()}}
                        style={{ zIndex: "1", height: "100% !important" }}
                    />
                    <div className="desk-upload-button-container">
                      <button className="desk-upload-button">
                      <img src="/assets/file/images/addimage.svg" alt="plus" />
                    </button>
                    </div>
                  </div>
                </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UploadImage;
