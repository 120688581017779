import React from 'react'
import "./second-onboard-screen.css"
import useWindowDimensions from "../../../helpers/hooks/windowDemensions";

const SecondOnboardScreen = ({show, firstOption, secondOption, onClickOut, onSkip}) => {
    const {height, width} = useWindowDimensions();
    return (
        <div onClick={onClickOut} className={`second-screen ${show ? "show" : ""}`}>
            <div onClick={e => e.stopPropagation()} className="second-screen-content">
                <div className="second-screen-header">
                    <div className="second-screen-container">
                        <div className="second-screen-top-text">
                            <p className="second-screen-first-top-text">סיכום <br/>ומשלוח</p>
                            <p>בחירת<br/>תמונות</p>
                        </div>
                        <img className="second-screen-arrow" src="../../assets/images/onboard-arrow.svg" alt={"Arrow"}></img>
                        <p className="second-screen-arrow-text">אתם כאן</p>
                        <div className="second-screen-under-top-text">
                            <div className="second-screen-first-under-top-text"></div>
                            <div></div>
                        </div>
                        <ul className="second-screen-progressbar">
                            <li></li>
                            <li className="second-screen-active">
                                <div className="second-screen-active-inside"></div>
                            </li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div className="second-screen-body">
                {width <= 370 ? (
                    <h5 className="second-screen-first-text">
                        <br/>
                        עבור מי התמונות                    
                    </h5>
                ) : (                    
                    <h5 className="second-screen-first-text">
                        <br/>
                        עבור מי התמונות?                    
                    </h5>
                )}
                {width > 767 ? (
                    <p className="second-screen-second-text"> הבחירה שלכם תעזור לנו להבין טוב יותר איך לארוז את האריזה </p>
                ) : ( <></> )}
                    
                    
                {width < 767 ? 
                (  
                    <div className="second-screen-options">
                    <div onClick={firstOption} className="second-screen-first-option">
                        <h6 className="second-screen-options-text">עבורי</h6>
                        <img className="second-screen-option-img" src="../../assets/images/self-icon.png" alt={"Character"}></img>
                    </div>
                        <hr className={"second-screen-option-hr"}></hr>
                        <div onClick={secondOption} className="second-screen-second-option">
                            <h6 className="second-screen-options-text">עבור קרוב (מתנה)</h6>
                            <img className="second-screen-option-img" src="../../assets/images/gift-icon.png" alt={"Character"}></img>
                        </div>
                        <div className='onboarding-smallt-mob'>
                            <p>כיף להיות בבית מאורגן עם תמונות של הסובבים</p>
                            <img src='../assets/images/info-circle.svg' alt='info'/>
                        </div>
                        </div>
                    )
                     : 
                     (                    
                        <div className="second-screen-options">
                        <div onClick={firstOption} className="second-screen-first-option">
                            <h6 className="second-screen-options-text">עבורי</h6>
                            <img className="second-screen-option-img" src="../../assets/images/self-icon.png" alt={"Character"}></img>
                        </div>
                        <hr className={"second-screen-option-hr"}></hr>
                        <div onClick={secondOption} className="second-screen-second-option">
                            <h6 className="second-screen-options-text">עבור קרוב (מתנה)</h6>
                            <img className="second-screen-option-img" src="../../assets/images/gift-icon.png" alt={"Character"}></img>
                        </div>
                        </div>
                    )}

                    {/* <p className="second-screen-skip" onClick={onSkip}>דלג</p> */}
                    
                </div>
                <div className="second-screen-footer">
                    <div className="second-screen-progress-skip">
                    <p className='onboarding-indicator'>1/2</p>
                        <div className="second-screen-progress">
                            <div className="second-screen-first-circle"></div>
                            <div className="second-screen-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondOnboardScreen;
