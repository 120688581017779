import './style.css';

const AccessState = () => {
	return (<div className="aboutus_access_mobile">
		שלום, אנחנו בבלנדס משתדלים להשאר נגישים עבור כלל אוכלוסיית מדינת ישראל ולאפשר לכולם שימוש בשירותים שלנו, האתר כתוב ומונגש בשפה העברית, בעזרת כפתורים גדולים ובולטים כך תוכלו להשתמש בשירותים שלנו בפשטות וביעילות.
		<br/><br/>
		עבור אנשים בעלי כבדות שמיעה, ניתן לבצע הזמנה דרך הוואצאפ ומול נציג אנושי שילווה אתכם בתהליך הרכישה בהתכתבות.
		<br/><br/>
		אין הגעה פיזית ללקוחות והשירותים שאנו מציעים מתבצעים בצורה דיגיטלית.
		<br/><br/>
		דרכי התקשרות לכבדי שמיעה:<br/>
		בוואצאפ במס’: 052-4321272<br/>
		מייל: hi@blends.co.il<br/>
		<br/>
		דרכי התקשרות לכבדי ראייה:<br/>
		ניתן להעזר באיש מלווה וליצור קשר עם שירות הלקוחות בדרכים הבאות:<br/>
		מייל: hi@blends.co.il<br/>
		בתיאום מראש דרך המייל ניתן לקבל שיחה חוזרת טלפונית.
	</div>)
}

export default AccessState