import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import cn from "classnames";
import axios from "axios";
import "cropperjs/dist/cropper.css";
import {
  popUpImage,
  imagesData,
  aboutUs,
  loaderState,
  imageFrameState,
  imagesBlobState,
  currentLocationState,
} from "@atoms";
import {
  countState,
  imageCountState,
} from "@atoms/priceCalc";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {useModal} from "@helpers/hooks/useModal";
import useWindowDimensions from "@helpers/hooks/windowDemensions";
import {removeImage, addUserCount, getImagesDB} from "@api";
import {
  DEFAULT_COUPON,
  discountConfig,
} from "@helpers/discounts";
import "./style.css";
import {useSecondModal} from "../../helpers/hooks/useSecondModal";
import {netPriceState} from "../../Recoil/Atoms/priceCalc";
import AddBWFilter from "../../Shared/Modals/AddBWFilter";
import FrameSelector from "./components/FrameSelector";
import ImageLoader from "@shared/ImageLoader";
import ShippingIcon from "./components/ShippingIcon";
import MobileIcon from "./components/MobileIcon";
import {
  addFilterState,
  bwFilterState,
  croppedImagesState,
  imagesLoadingState,
  imageUploadState,
  validCoupon,
} from "../../Recoil/Atoms";
import DeliveryDateRange from './deliverydates';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const showUploadOptionsIconStyling = {
  color: "#087E63",
  bottom: "225px",
  position: "absolute",
};

const handleAboutUsModalStyling = {
  color: "black",
  top: "70px",
  position: "absolute",
  fontWeight: 300,
};

function getFrameSelected() {
  const frameSelected = localStorage.getItem("frameSelected");
  if (!frameSelected) return "classic";
  return frameSelected;
}

const ReviewImages = () => {
  const [currentLocation, setCurrentLocation] = useRecoilState(currentLocationState);
  const [, setimageonpopup] = useRecoilState(popUpImage);
  const [images] = useRecoilState(imagesData);
  const [, setFirstLoader] = useState("block");
  const [loader] = useRecoilState(loaderState);
  const [uploadImages, setUploadImages] = useRecoilState(imageUploadState);
  const netPrice = useRecoilValue(netPriceState);
  const setSelectedCoupon = useSetRecoilState(validCoupon);
  const [croppedImages, setCroppedImages] = useRecoilState(croppedImagesState);
  const [finalPriceState, setFinalPriceState] = useState(0);
  const [, settransac] = useState();
  const [, setloaderdis] = useState(false);
  const [socialopc] = useState("none");
  const [blobs, setBlobs] = useState([]);
  const [frameChoose, setFrameChoose] = useRecoilState(imageFrameState);
  const [imagesBlob] = useRecoilState(imagesBlobState);
  const [showAddFilter, setShowAddFilter] = useRecoilState(addFilterState);
  const [isBwFilter, setIsBwFilter] = useRecoilState(bwFilterState);

  const secondModal = useSecondModal();
  const [, setAboutUs] = useRecoilState(aboutUs);

  setFrameChoose(getFrameSelected());
  setCurrentLocation(window.location.pathname);

  const [count, setCount] = useRecoilState(countState);
  const [imagecount] = useRecoilState(imageCountState);
  const [isImagesLoading, setIsImagesLoading] = useRecoilState(imagesLoadingState);

  const {height, width} = useWindowDimensions();
  let history = useHistory();
  const modal = useModal();
  const Frames = [
    { name: "classic", text: "קלאסי" },
    { name: "bold", text: "נועז", label: "פופולארי" },
    { name: "ever", text: "נקי" },
    { name: "clean", text: "אותנטי" },
  ];
  const selectedFrameText = Frames.find(frame => frame.name === frameChoose)?.text;
  useEffect(() => {
    if (imagesBlob.length === 0) {
      return setIsImagesLoading(false);
    }

    setIsImagesLoading(true);
  }, [imagesBlob]);

  useEffect(() => {
    setBlobs((prevState) => {
      if (prevState.length === 0) {
        return [...imagesBlob];
      }
      const newArr = [...prevState];
      if (imagesBlob.length > 0) {
        imagesBlob.forEach((blob) => {
          if (!newArr.map((im) => im.id).includes(blob.id)) {
            newArr.push(blob);
          }
        });
      }
      return newArr;
    });
  }, [imagesBlob]);

  const checkValidCoupon = useCallback(() => {
    const totalImageCount = imagecount + imagesBlob.length;
    const price = netPrice + (imagesBlob.length * 45);

    const maxDiscountAmountAvailable = Object.keys(discountConfig)
      .sort((a, b) => Number(b) - Number(a))
      .find((key) => Number(key) <= totalImageCount);

    if (!maxDiscountAmountAvailable) {
      setSelectedCoupon(null);
      return;
    }

    const currentDiscountConfig = discountConfig[maxDiscountAmountAvailable];

    if (!currentDiscountConfig.enableDefault) {
      setSelectedCoupon(null);
      return;
    }

    const finalPrice = price * (currentDiscountConfig.discount / 100);

    setFinalPriceState(finalPrice);

    setSelectedCoupon(
      {
        coupon: DEFAULT_COUPON,
        discount: currentDiscountConfig.discount,
        discountPrice: finalPrice,
      });

  }, [imagesBlob.length, netPrice, setSelectedCoupon]);

  useEffect(() => {
    checkValidCoupon();
  }, [checkValidCoupon]);

  const setFrameSelected = (frame) => {
    localStorage.setItem("frameSelected", frame);
    setFrameChoose(frame);
  };
  const storagedata = localStorage.getItem("uniqueUserId");
  if (!storagedata) {
    history.push("/");
  }

  const handleAboutUsModal = (type) => {
    setAboutUs(type);
    if (width > 768) {
      secondModal("open", "aboutUs");
    } else {
      secondModal("open", "aboutUsMobile");
    }
  };

  const keep_image = (id) => {
    axios.put(BASE_URL + "/image_keep/" + id).then((v) => {
    });
  };

  useEffect(() => {
    setloaderdis(true);
    getimagesdb();
    addUserCount();
  }, []);

  useEffect(() => {
    const lowImage = images.find((i) => (i.imageheight < 800 || i.imagewidth < 800) && !i.keepImage);
    if (lowImage) {
      modal("open", "deleteConfirm", {
        data: lowImage,
        onKeepImage: (id) => keep_image(id),
        onDelete: () =>
          removeImage(lowImage._id).then(() => {
            setCount(count + 1);
            getImagesDB().then((res) => {
              if (res.data.data.length === 0) {
                localStorage.setItem("dataExist", 0);
                history.push("/upload-your-image");
              }
            });
          }),
      });
    }
  }, [images]);

  const handleFilterUpdate = async () => {
    try {
      const localstr = localStorage.getItem("uniqueUserId");
      if(isBwFilter) {
        await axios.post(`${BASE_URL}/add-bw-filter/${localstr}`);
      } else {
        await axios.post(`${BASE_URL}/remove-bw-filter/${localstr}`);
      }

      await getImagesDB();

      setShowAddFilter(false);
    } catch (err) {
      console.log(err);
    }
  }

  const edit_seg = (e, correspondingBlob, imagesBlob) => {
    setimageonpopup(e);
    modal("open", "editImage", {frame: frameChoose, correspondingBlob: correspondingBlob, imagesBlob: imagesBlob});
  };

  const getimagesdb = () => {
    getImagesDB().then((res) => {
      setFirstLoader("none");
      settransac("none");
      setloaderdis(false);
    });
  };

  const deleteImage = (item) => {
    const id = item._id;
    removeImage(id).then((v) => {
      setCount(count + 1);
      getImagesDB().then((res) => {
        if (res.data.data.length === 0) {
          localStorage.setItem("dataExist", 0);
          history.push("/upload-your-image");
          window.location.reload(true);
        }
      });
    });
  };

  const frameSelected = useCallback(async (type) => {
    setFrameSelected(type);
    const userid = localStorage.getItem("uniqueUserId");

    const response = await axios.post(
      BASE_URL + "/frameselected",
      {
        userid,
        type,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      },
    );
  }, []);

  const showUploadOptions = () => {
    modal("open", "uploadOptions", {}, 400);
  };

  const openImageOptions = (img, correspondingBlob, imagesBlob, isBlob, isCropped) => {
    if (width > 767) return;
    modal("open", "imageOptions", {
      editImage: () => edit_seg(img, correspondingBlob, imagesBlob),
      deleteImage: () => deleteImage(img),
      isBlob: isBlob,
      isCropped: isCropped,
    });
  };

  const openCheckoutDrawer = () => {
    modal("open", "checkout");
  };

  const openCheckoutDrawerMobile = () => {
    modal("open", "mobileCheckout");
  };

  if (width < 767 && currentLocation === "/review-your-images" && uploadImages) {
    document.getElementById("my-album-id").scrollLeft += (images.length + imagesBlob.length) * 250;
    setTimeout(() => {
      setUploadImages(false);
    }, 500);
  }


  const numOfframes = [imagecount + imagesBlob.length];
  let priceBeforediscount = numOfframes * 45;
  let difference = priceBeforediscount - finalPriceState;
  const renderImages = useMemo(() => {
    const concatenatedArray = [...images, ...imagesBlob]
      .sort((firstImage, secondImage) => firstImage.uploadedAt - secondImage.uploadedAt);

    return concatenatedArray.map((im, index) => {
      const correspondingBlob = blobs.find(blob => blob.id === im.publicId);
      return (
        <div className={`album-item-wrapper`} key={`${index.toString()}`}>
          <div className="album-item-shadow"></div>
          {/* <div className="album-item-dimension">
            <p>20x20</p>
            </div> */}
          <div
            className={cn(`album-item pop-up ${frameChoose}`)}
            key={`${frameChoose}`}
            onClick={() => {
              /*if (imagesBlob.includes(im)) {
                return;
              }*/
              openImageOptions(im, correspondingBlob, imagesBlob, imagesBlob.includes(im),
                croppedImages.some(img => img._id === im._id));
            }}
          >
            {width > 767 && (
              <div className={`content-overlay ${frameChoose}`}>
                <div className="content-details fadeIn-bottom">
                  <img
                    src="/assets/file/images/edit_image.svg"
                    className="עריכת תמונה"
                    data-toggle="modal"
                    data-target="#myModal"
                    onClick={() => {
                      edit_seg(im, correspondingBlob, imagesBlob);
                    }}
                    alt="edit"
                  />
                  <>
                    <img
                      src="/assets/file/images/Delete_icon.svg"
                      className={`edit ${(imagesBlob.includes(im) || croppedImages.some(img => img._id === im._id)) &&
                      "disabled"}`}
                      alt="הסרת תמונה"
                      onClick={() => {
                        if (imagesBlob.includes(im) || croppedImages.some(img => img._id === im._id)) {
                          return;
                        }
                        deleteImage(im);
                      }}
                    />
                  </>
                </div>
              </div>
            )}
          </div>
          <ImageLoader
            loadingBlob={correspondingBlob ? correspondingBlob.url : undefined}
            loading={false}
            src={imagesBlob.includes(im) ? im.url : im.crop_image}
            zoomvalue={!imagesBlob.includes(im) && im.zoomvalue}
            bwFilter={im ? im.bwFilter : false}
            loaderClassName={cn(`img-con ${frameChoose} blurred-loader-image ${frameChoose}`)}
            className={cn(`img-con ${frameChoose} ${imagesBlob.includes(im) ? `with-animation ${frameChoose}` : ``}`)}
            alt="icon"
            shadow={true}
          />
        </div>
      );
    });
  }, [images, imagesBlob, blobs, frameChoose, width, openImageOptions, edit_seg, deleteImage]);

  return (
    <>
      <AddBWFilter
        show={showAddFilter}
        onFilterClose={handleFilterUpdate}
        onClose={() => {setShowAddFilter(false)}}
        isAdd={isBwFilter}
      />
{width <= 767 &&
  <div className="reviews-info-mobile-cart">
    <div className="svg-container" onClick={openCheckoutDrawerMobile}>
      <img src="/assets/images/cart.svg" alt=""/>
      <span className={imagecount + imagesBlob.length >= 10 ? "overlay-text-10" : "overlay-text"}>
      {imagecount + imagesBlob.length}
      </span>
    </div>
  <div className="review-infos-mobile-top">
  <div className="review-infos-mobile">
  <div className="prices-container">
    {/* <p onClick={() => handleAboutUsModal("CQ")} text="?">יש לכם שאלה?</p> */}
    { imagecount + imagesBlob.length >= 6 ? (
      <>
        <span className="difference">₪{difference}</span>
        <span className="price-strikethrough">₪{priceBeforediscount}</span>
      </>
    ) : (
      <span className="difference">₪{priceBeforediscount}</span>
    )}
  </div>
  <span className="delivery-date-range"><DeliveryDateRange/></span>
</div>
</div>
</div>
}

      {width > 767 && (
        <div className="uploaded_img_pg">
          <FrameSelector
            frameChoose={frameChoose}
            frameSelected={frameSelected}
          />
        </div>
      )}
      <div className="review-images-wrapper" style={{maxHeight: width < 768 ? height - 305 : null}}>
        {width > 767 && (
          <div className="checkout-wrapper">
            <div className="aside-checkout">
              {imagecount + imagesBlob.length > 4 &&
                <>
                  <h1>יש לנו כאן {imagecount + imagesBlob.length} תמונות</h1>
                  <p>וודאו שהכל נראה טוב ונמשיך</p>
                </>
              }
              {imagecount + imagesBlob.length === 4 &&
                <>
                  <h1>יש לנו כאן {imagecount + imagesBlob.length} תמונות</h1>
                  <p class="remaining-shipping-text"><ShippingIcon />&nbsp;&nbsp;&nbsp;תוסיפו עוד תמונה אחת וקבלו משלוח
                    חינם
                  </p>
                </>
              }
              {imagecount + imagesBlob.length === 3 &&
                <>
                  <h1>יש לנו כאן {imagecount + imagesBlob.length} תמונות</h1>
                  <p class="remaining-shipping-text"><ShippingIcon />&nbsp;&nbsp;&nbsp;תוסיפו עוד 2 תמונות וקבלו משלוח
                    חינם
                  </p>
                </>
              }
              {imagecount + imagesBlob.length === 2 &&
                <>
                  <h1>יש לנו כאן {imagecount + imagesBlob.length} תמונות</h1>
                  <p class="remaining-shipping-text"><ShippingIcon />&nbsp;&nbsp;&nbsp;תוסיפו עוד 3 תמונות וקבלו משלוח
                    חינם
                  </p>
                </>
              }
              {imagecount + imagesBlob.length === 1 &&
                <>
                  <h1>בחרתם תמונה אחת</h1>
                  <p class="remaining-shipping-text"><ShippingIcon />&nbsp;&nbsp;&nbsp; תוסיפו עוד 4 תמונות וקבלו משלוח
                    חינם</p>
                </>
              }
              {imagecount + imagesBlob.length === 0 &&
                <>
                  <h1>אין לנו כאן תמונות</h1>
                  <p>נסו לחזור אחורה ולהתחיל שוב או דווחו לנו</p>
                </>
              }
              {imagecount + imagesBlob.length === 0 &&
                <button className="checkout-btn" onClick={showUploadOptions} style={{width: "auto"}}>
                  בחירת תמונות חדשות
                </button>
              }
              {imagecount + imagesBlob.length >= 1 && imagecount + imagesBlob.length <= 4 &&
                <>
                  <button className="checkout-btn" onClick={openCheckoutDrawer}>
                    סיכום ומשלוח
                  </button>
                  <br /><br />
                  <p>עלות משלוח: 29.90₪</p>
                </>
              }
              {imagecount + imagesBlob.length > 4 &&
                <>
                  <button className="checkout-btn" onClick={openCheckoutDrawer}>
                    סיכום ומשלוח
                  </button>
                  <br /><br />
                  <p class="freeshippingdesktext" style={{color: "#087E63"}}><img style={{width: "20px"}}
                     src="/assets/images/shipping-green.svg"
                    alt="" />&nbsp; ההזמנה עומדת בתנאים
                    למשלוח מהיר חינם
                  </p>
                </>
              }
              <div className="gift">
                <h2 className="">:הזמנה זו כוללת</h2>
                <ul className="gift-list">
                  <li>
                    אריזה לשמירה על התמונות
                    <img src="/assets/file/images/bag-happy.svg"
                         alt="קרטון עם מילוי לשמירה על תמונות קיר" />
                  </li>

                  <li>
                    שליח עד הבית
                    <img
                      src="/assets/file/images/truck-tick.svg"
                      alt="שליח דואר ישראל על לביתכם"
                    />
                  </li>

                  <li>
                    החזרה מהירה בקלות
                    <img src="/assets/file/images/3d-rotate.svg"
                         alt="החזרת סחורה באי שביעות רצון ללא שאלות מיותרות" />
                  </li>

                  <li>
                    עסקה מאובטחת
                    <img src="/assets/file/images/shield-tick.svg"
                         alt="קופון הנחה בלנדס" />
                  </li>
                  <img src="https://www.facebook.com/tr?id=266939557094379&ev=UploadedImagesDesktop" height="1"
                       width="1" alt="" />
                </ul>
              </div>
            </div>
          </div>
        )}
        <div className="trx" style={width <= 767 ? {
          display: "grid",
          alignItems: "center",
          height: `calc(${height}px - 50px)`,
          paddingTop: "0px",
          position: "fixed",

        } : null}>
          <div
            id={"my-album-id"}
            className={cn("my-album", {single: images.length + imagesBlob.length <= 1})}
            style={{
              gridTemplateColumns:
                width <= 767 ? `repeat(${images.length + imagesBlob.length}, 250px)` : null,
            }}
          >
            {renderImages}

            <div
              className="add-photo-to-album"
              onClick={() => showUploadOptions()}
            ></div>
          </div>
        </div>
      </div>

      {width <= 767 && (

        <div className="mobile-checkout">
          <div>
            <FrameSelector
              frameChoose={frameChoose}
              frameSelected={frameSelected}
            />
          </div>

          <MobileIcon styling={showUploadOptionsIconStyling} onClick={() => showUploadOptions()} text="+" />

          <div className="mobile-checkout-info">
            {imagecount + imagesBlob.length >= 5 &&
              <p style={{marginTop: "13px", fontWeight: "400"}}>
                <img src="https://www.facebook.com/tr?id=266939557094379&ev=UploadedImages" height="1" width="1"
                     alt="" />
                 <img src="/assets/images/shipping-green.svg" alt="check"
                     style={{width: "20px"}} />&nbsp;
                 מעולה! קיבלתם שליח עד הבית ללא עלות
              </p>
            }
            {imagecount + imagesBlob.length === 4 &&
              <p style={{color: "#727272"}}>
                <img src="https://www.facebook.com/tr?id=266939557094379&ev=UploadedImages" height="1" width="1"
                     alt="" />
                <img className="notice" src="assets/images/review-notice.svg" alt="" />&nbsp;&nbsp; תוסיפו עוד תמונה אחת
                וקבלו משלוח בחינם
              </p>
            }
            {imagecount + imagesBlob.length === 3 &&
              <p style={{color: "#727272"}}>
                <img src="https://www.facebook.com/tr?id=266939557094379&ev=UploadedImages" height="1" width="1"
                     alt="" />
                <img className="notice" src="assets/images/review-notice.svg" alt="" />&nbsp;&nbsp; תוסיפו עוד 2 תמונות
                וקבלו משלוח בחינם
              </p>
            }
            {imagecount + imagesBlob.length === 2 &&
              <p style={{color: "#727272"}}>
                <img src="https://www.facebook.com/tr?id=266939557094379&ev=UploadedImages" height="1" width="1"
                     alt="" />
                <img className="notice" src="assets/images/review-notice.svg" alt="" />&nbsp;&nbsp; תוסיפו עוד 3 תמונות
                וקבלו משלוח בחינם
              </p>
            }
            {imagecount + imagesBlob.length === 1 &&

              <p style={{color: "#727272"}}>
                <img src="https://www.facebook.com/tr?id=266939557094379&ev=UploadedImages" height="1" width="1"
                     alt="" />
                <img className="notice" src="assets/images/review-notice.svg" alt="" />&nbsp;&nbsp; תוסיפו עוד 4 תמונות
                וקבלו משלוח בחינם
              </p>

            }
          </div>
          <div className="mobile-checkout-button" style={{width: "60%", display: "flex", justifyContent: "center", alignItems: "center", margin: "auto"}}>
            {imagecount + imagesBlob.length === 0 &&
              <button
                className="mobile-checkout-btn"
                data-toggle="modal"
                data-target="#rescart"
                onClick={showUploadOptions}
              >
                בחירת תמונות
              </button>
            }
            {imagecount + imagesBlob.length > 1 &&
              <button
                className="mobile-checkout-btn"
                data-toggle="modal"
                data-target="#rescart"
                onClick={openCheckoutDrawerMobile}
              >
                {/* מעבר לעגלה ({imagecount + imagesBlob.length} תמונות) */}
                סיכום ומשלוח
              </button>
            }
            {imagecount + imagesBlob.length === 1 &&
              <button
                className="mobile-checkout-btn"
                data-toggle="modal"
                data-target="#rescart"
                onClick={openCheckoutDrawerMobile}
              >
                {/* המשך לעגלה עם תמונה אחת */}
                סיכום ומשלוח
              </button>
            }
          </div>
        </div>
      )}
      <div className="body_opcy" style={{display: socialopc}}></div>
    </>
  );
};

export default ReviewImages;
