import axios from "axios";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {checkOrderStatus} from "../../../api/requests";
import {OrderStatus} from "../../../constants/enums/orderStatus";
import "./bitpay.css";
import {secondaryModals, secondOverlayState, selectedShippingAddress} from "../../../Recoil/Atoms";
import bitclose from "./bitclose.svg";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const BitPayModal = ({data}) => {
  const [, setOverlay] = useRecoilState(secondOverlayState);
  const [timer, setTimer] = useState(600);
  const [isSecondsPassed, setIsSecondsPassed] = useState(false);
  const [isRefreshClicked, setIsRefreshClicked] = useState(false);
  const selectedAddress = useRecoilValue(selectedShippingAddress);
  const [orderStatus, setOrderStatus] = useState(OrderStatus.NOT_PAID);
  const history = useHistory();
  const setModals = useSetRecoilState(secondaryModals);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (localStorage.getItem("orderId")) {
        if(orderStatus === OrderStatus.PAID){
          const oid = localStorage.getItem("orderId");
          handleClose();
          localStorage.clear();
          history.push(`/payment-success/${oid}`);
        }

        if (orderStatus === OrderStatus.NOT_PAID) {
          checkOrderStatus().then((resp) => {
            if (resp && resp.data && resp.data.orderStatus === OrderStatus.PAID) {
              setOrderStatus(OrderStatus.PAID);
              const oid = localStorage.getItem("orderId");
              handleClose();
              localStorage.clear();
              history.push(`/payment-success/${oid}`);
            } else if (resp && resp.data && resp.data.orderStatus === OrderStatus.ERROR) {
              setOrderStatus(OrderStatus.ERROR);
              setModals(state => ({
                ...state,
                bitPay: {visible: false},
                selectCard: {visible: false},
                errorCart: {visible: true},
              }));
              setOverlay(false);
              localStorage.removeItem("orderId");
            }
          });
        }
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleBitPayment = async () => {
    if (isRefreshClicked) {
      return;
    }

    const checkResp = await checkOrderStatus();
    if (checkResp.data && checkResp.data.orderStatus === OrderStatus.PAID) {
      setOrderStatus(OrderStatus.PAID);
      return;
    }

    let gamaAuthResponse;
    const gamaAccessToken = localStorage.getItem("gamaAccessToken");
    const expiresAt = localStorage.getItem("gamaAccessTokenExpiresAt");

    try {
      if (!gamaAccessToken || expiresAt <= Date.now()) {
        gamaAuthResponse = await axios.post(`${BASE_URL}/gama-login`);

        if (gamaAuthResponse.data.status !== 200) {
          throw new Error("Gama auth failed.");
        }

        localStorage.setItem("gamaAccessToken", gamaAuthResponse.data.access_token);
        localStorage.setItem("gamaAccessTokenExpiresAt", (Date.now() + gamaAuthResponse.data.expires_in));
      }

      const gamaSessionResponse = await axios.post(`${BASE_URL}/gama-session`, {
        gamaAccessToken: localStorage.getItem("gamaAccessToken"),
      });

      if (gamaSessionResponse.data.status !== 200) {
        throw new Error("Gama session creating failed.");
      }

      const id = localStorage.getItem("orderId");

      const createTransactionResponse = await axios.post(`${BASE_URL}/gama-create-payment`, {
        orderId: id,
        amount: data,
        payerName: selectedAddress.fullName,
        payerPhoneNumber: selectedAddress.phoneNumber,
        transactionId: gamaSessionResponse.data.transactionProcessingId,
        gamaAccessToken: localStorage.getItem("gamaAccessToken"),
        gamaSessionToken: gamaSessionResponse.data.token,
        transactionEndpoint: gamaSessionResponse.data.transactionProcessingEndpoint,
      });

      if (createTransactionResponse.data.status !== 200) {
        throw new Error("Gama transaction creating failed.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setModals(state => ({
      ...state,
      bitPay: {visible: false},
      selectCard: {visible: false},
    }));
    setOverlay(false);
  };

  const handleRefreshClick = async () => {
    if (isSecondsPassed) {
      setTimer(600);
      await handleBitPayment();
      setIsRefreshClicked(true);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalId);
          handleClose();
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsSecondsPassed(true);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="bitbody">
      <img src={bitclose} onClick={handleClose} alt="" className="bitclose" />
      <div className="bitheader">
        <div className="titletext">
          שלום,
        </div>
        <div className="secondtitletext">
          בחרתם לשלם באמצעות אפליקציית ביט
        </div>
        <img src="/assets/images/bit1.png" alt="" />
      </div>
      <hr style={{
        color: "white",
        height: "3px", width: "70%",
        marginLeft: "15%", marginRight: "15%",
      }}
      />
      <div className="bitmiddle">
        <p className="bitmiddlenotice">ברגעים אלו נשלח אליך מסרון לנייד לביצוע התשלום באמצעות אפליקציית ביט
        </p>
        <p className="bitamounttitle">
          סכום לתשלום
        </p>
        <p className="bitamount">
          {data} ש"ח
        </p>
      </div>
      <hr style={{
        background: "white", color: "white", borderColor: "white",
        height: "3px", width: "70%",
        marginLeft: "15%", marginRight: "15%",
      }}
      />
      <div className="bitbottomarea">
        <div className="bitrefresh" onClick={() => handleRefreshClick()}>
          <p style={{cursor:'pointer'}}>שלח מחדש</p>
        </div>
        <div className="bitwait">
          ממתין לאישור תשלום באפליקציית ביט
        </div>
        <div className="bittimer">{formatTime(timer)}</div>
      </div>
    </div>
  );
};

export default BitPayModal;