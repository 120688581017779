
import useWindowDimensions from "@helpers/hooks/windowDemensions";
export const Mouse = () => {
  const { height, width } = useWindowDimensions();
  const isMobile = width < 767
    return (
    <svg width={isMobile ? 30 : 50} height={isMobile ? 30 : 50} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56.8334 31V23.25C56.8334 10.3333 51.6668 5.16667 38.7501 5.16667H23.2501C10.3334 5.16667 5.16675 10.3333 5.16675 23.25V38.75C5.16675 51.6667 10.3334 56.8333 23.2501 56.8333H31.0001" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M54.1467 46.0866L49.9358 47.5075C48.7733 47.895 47.8433 48.7992 47.4558 49.9875L46.035 54.1983C44.8208 57.8408 39.7058 57.7634 38.5692 54.1209L33.79 38.75C32.86 35.7017 35.6758 32.86 38.6983 33.8158L54.095 38.595C57.7117 39.7317 57.7633 44.8725 54.1467 46.0866Z" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
 </svg>
    )
}
