/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import {
    countState,
    netPriceState,
    imageCountState,
    totalPriceState,
    discountPriceState,
    discountPercentageState,
} from "@atoms/priceCalc";
import {imagesData} from "@atoms";
import {getRecoil, setRecoil} from "recoil-nexus";
import {imagesBlobState, loaderState} from "../Recoil/Atoms";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export async function uploadSingleImage(formdata) {
    if(localStorage.getItem("orderId")) {
        localStorage.removeItem("orderId");
    }

    const response = await axios
        .post(`${BASE_URL}/imageupload_single`, formdata, {
            headers: {
                "content-type": "multipart/form-data",
            },
            //responseType: "blob",
        })

    await getImagesDB();

    return response;
}

export async function uploadMultipleImages(formdata) {
    const response = await axios
        .post(`${BASE_URL}/imageupload`, formdata, {
            headers: {
                "content-type": "multipart/form-data",
            },
        })

    await getImagesDB();

    return response;
}

export function removeImage(id) {
    if(localStorage.getItem("orderId")) {
        localStorage.removeItem("orderId");
    }

    return axios.get(`${BASE_URL}/delete_image_by_user/${id}`);
}

export function getUserImages() {
    const localstr = localStorage.getItem("uniqueUserId");
    return axios.get(`${BASE_URL}/get_image_by_user/${localstr}`);
}

export const addUserCount = async () => {
    const config = {
        headers: {
            "content-type": "application/json",
        },
    };
    return await axios.get(`${BASE_URL}/user/addnewusercount`, config);
};

export async function addImageFromSocial(response) {
    if(localStorage.getItem("orderId")) {
        localStorage.removeItem("orderId");
    }

    const rep = await axios.post(`${BASE_URL}/social-photo-import`, response, {
        headers: {
            "content-type": "application/json",
        },
    })

    await getImagesDB();

    return rep;
}

export async function getImagesDB() {
    const count = getRecoil(countState);
    const imageBlobs = getRecoil(imagesBlobState);
    const imageData = await getUserImages();
    const loadedImagesIds = imageData.data.data.map(item => item.publicId);

    const filteredBlobs = imageBlobs.filter(oldBlob => !loadedImagesIds.includes(oldBlob.id));


    if (filteredBlobs.length === 0 && imageBlobs.length > 0) {
        setRecoil(loaderState, {
            showText: false,
            showLoader: true,
            show50: false,
            show100: true,
        });

        setTimeout(() => {
            setRecoil(loaderState, {
                showLoader: false,
                show50: false,
                show100: false,
                showText: true,
            });

            setTimeout(() => {
                setRecoil(loaderState, {
                    showLoader: false,
                    show50: false,
                    show100: false,
                    showText: false,
                });
            }, 3500);
        }, 500);
    }
    setRecoil(countState, count + 2);
    if (imageData.data.data.length > 0) {
        const imageshow = imageData.data.data;
        setRecoil(imagesData, imageshow);
        setRecoil(imageCountState, imageshow.length);

        let set_net_price = imageshow.length * 45;
        let set_discount_percentage = 0;
        let set_discount_price = 0;
        let set_total_price = 0;

        if (imageshow.length > 9 && imageshow.length < 15) {
            set_discount_percentage = 5;
            set_discount_price = (
                (set_discount_percentage / 100) *
                parseFloat(set_net_price)
            ).toFixed(2);
            set_total_price = set_net_price - set_discount_price;
        } else if (imageshow.length > 14) {
            set_discount_percentage = 10;
            set_discount_price = (
                (set_discount_percentage / 100) *
                parseFloat(set_net_price)
            ).toFixed(2);
            set_total_price = set_net_price - set_discount_price;
        }

        setRecoil(imagesBlobState, filteredBlobs);
        setRecoil(netPriceState, set_net_price);
        setRecoil(discountPercentageState, set_discount_percentage);
        setRecoil(discountPriceState, set_discount_price);
        setRecoil(totalPriceState, set_total_price);
    }
    return imageData;
}

export async function checkOrderStatus() {
    try {
        const response = await axios.get(`${BASE_URL}/user/checkorder/${localStorage.getItem("orderId")}`);
        console.log(response.data);

        return response;
    } catch (err) {
        console.log(err);
    }
}
