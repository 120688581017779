import axios from "axios";
import {useEffect, useState} from "react";
import {isAndroid, isIOS} from "react-device-detect";
import {useHistory} from "react-router-dom";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {checkOrderStatus} from "../../../api/requests";
import {OrderStatus} from "../../../constants/enums/orderStatus";
import {useSecondModal} from "../../../helpers/hooks/useSecondModal";
import "./bitpaymobile.css";
import {secondaryModals, secondOverlayState, selectedShippingAddress} from "../../../Recoil/Atoms";
import bitclose from "../BitPay/bitclose.svg";

const BASE_URL = process.env.REACT_APP_BASE_URL;


const BitPayMobileModal = ({data}: { amountCharged: string, strLink: string }) => {
  const modal = useSecondModal();
  const setModals = useSetRecoilState(secondaryModals);
  const [, setOverlay] = useRecoilState(secondOverlayState);
  const [isLoading, setIsLoading] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const [timer, setTimer] = useState(600);
  const [isSecondsPassed, setIsSecondsPassed] = useState(false);
  const [isRefreshClicked, setIsRefreshClicked] = useState(false);
  const [url, setUrl] = useState(data?.strLink);
  const selectedAddress = useRecoilValue(selectedShippingAddress);
  const [orderStatus, setOrderStatus] = useState(OrderStatus.NOT_PAID);
  const history = useHistory();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (localStorage.getItem("orderId")) {
        if(orderStatus === OrderStatus.PAID){
          const oid = localStorage.getItem("orderId");
          handleClose();
          localStorage.clear();
          history.push(`/payment-success/${oid}`);
        }

        if (orderStatus === OrderStatus.NOT_PAID) {
          checkOrderStatus().then((resp) => {
            if (resp && resp.data && resp.data.orderStatus === OrderStatus.PAID) {
              setOrderStatus(OrderStatus.PAID);
              const oid = localStorage.getItem("orderId");
              handleClose();
              localStorage.clear();
              history.push(`/payment-success/${oid}`);
            } else if (resp && resp.data && resp.data.orderStatus === OrderStatus.ERROR) {
              setOrderStatus(OrderStatus.ERROR);
              handleClose();
              modal("open", "errorCart");
              localStorage.removeItem("orderId");
            }
          });
        }
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleRedirectToPay = () => {
    window.open(url, "_blank");
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setModals(state => ({
        ...state,
        bitPayMobile: {visible: false, data: {}},
        selectCardMobile: {visible: false},
      }));
      setOverlay(false);
    }, 1000);
  };

  const handleBitPayment = async () => {
    if (isRefreshClicked) {
      return;
    }

    const checkResp = await checkOrderStatus();
    if (checkResp.data && checkResp.data.orderStatus === OrderStatus.PAID) {
      setOrderStatus(OrderStatus.PAID);
      return;
    }

    let gamaAuthResponse;
    const gamaAccessToken = localStorage.getItem("gamaAccessToken");
    const expiresAt = localStorage.getItem("gamaAccessTokenExpiresAt");

    try {
      if (!gamaAccessToken || expiresAt <= Date.now()) {
        gamaAuthResponse = await axios.post(`${BASE_URL}/gama-login`);

        if (gamaAuthResponse.data.status !== 200) {
          throw new Error("Gama auth failed.");
        }

        localStorage.setItem("gamaAccessToken", gamaAuthResponse.data.access_token);
        localStorage.setItem("gamaAccessTokenExpiresAt", (Date.now() + gamaAuthResponse.data.expires_in));
      }

      const gamaSessionResponse = await axios.post(`${BASE_URL}/gama-session`, {
        gamaAccessToken: localStorage.getItem("gamaAccessToken"),
      });

      if (gamaSessionResponse.data.status !== 200) {
        throw new Error("Gama session creating failed.");
      }

      const id = localStorage.getItem("orderId");

      const createTransactionResponse = await axios.post(`${BASE_URL}/gama-create-payment`, {
        orderId: id,
        amount: data.amountCharged,
        payerName: selectedAddress.fullName,
        payerPhoneNumber: selectedAddress.phoneNumber,
        transactionId: gamaSessionResponse.data.transactionProcessingId,
        gamaAccessToken: localStorage.getItem("gamaAccessToken"),
        gamaSessionToken: gamaSessionResponse.data.token,
        transactionEndpoint: gamaSessionResponse.data.transactionProcessingEndpoint,
      });

      if (createTransactionResponse.data.status !== 200) {
        throw new Error("Gama transaction creating failed.");
      }

      if (isAndroid) {
        setUrl(createTransactionResponse.data.walletProviderApplicationSchemeAndroid);
        console.log("Refresh Android");
      } else if (isIOS) {
        setUrl(createTransactionResponse.data.walletProviderApplicationSchemeIos);
        console.log("Refresh iOS");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefreshClick = async () => {
    if (isSecondsPassed) {
      setTimer(600);
      await handleBitPayment();
      setIsRefreshClicked(true);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalId);
          handleClose();
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsSecondsPassed(true);
    }, 20000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setIsLoading(false), 2000);

    return () => clearTimeout(loadingTimer);
  }, []);

  if (isLoading) {
    return <div className="spinner"></div>;
  }

  return (
    <div className={`bitbody ${!isLoading ? "fadeInModal" : ""} ${isClosing ? "modal-closing" : ""}`}>
      <img src={bitclose} onClick={handleClose} alt="" className="bitclose" />
      <div className="bitheader">
        <div className="titletext">
          שלום,
        </div>
        <div className="secondtitletext">
          בחרתם לשלם באמצעות אפליקציית ביט
        </div>
        <img src="/assets/images/bit1.png" alt="" />
      </div>
      <hr style={{
        color: "white",
        height: "3px", width: "70%",
        marginLeft: "15%", marginRight: "15%",
      }}
      />
      <div className="bitmiddle">
        <p className="bitamounttitle">
          סכום לתשלום
        </p>
        <p className="bitamount">
          {data?.amountCharged} ש"ח
        </p>
      </div>
      <hr style={{
        background: "white", color: "white", borderColor: "white",
        height: "3px", width: "70%",
        marginLeft: "15%", marginRight: "15%",
      }}
      />
      <div className="bitbottomarea">
        <button className="bitopenapp" onClick={() => handleRedirectToPay()}>לפתיחת אפליקציית ביט
        </button>
        <div className="bitrefresh" onClick={() => handleRefreshClick()}>
          <p>שלח מחדש</p>
        </div>
        <div className="bitwait">
          ממתין לאישור תשלום באפליקציית ביט
        </div>
        <div className="bittimer">{formatTime(timer)}</div>
      </div>
    </div>
  );
};

export default BitPayMobileModal;