import React, {useEffect, useState} from "react";
import cn from 'classnames';
import {useModal} from '@helpers/hooks/useModal';
import {useHistory} from "react-router-dom";
import uniqid from "uniqid";
import {
    uploadSingleImage,
    addImageFromSocial,
} from '@api';
import {
    imageCountState
} from "@atoms/priceCalc";
import {loaderState, imagesBlobState} from "@atoms/index";
import {useRecoilState, useRecoilValue} from "recoil";
import "./style.css";
import {imageUploadState} from "../../../Recoil/Atoms";

const UploadOptions = ({isOpen, style}) => {
    const imagecount = useRecoilValue(imageCountState);
    const [, setLoader] = useRecoilState(loaderState);
    const [imagesBlob, setImagesBlob] = useRecoilState(imagesBlobState);
    const [uploadImages, setUploadImages] = useRecoilState(imageUploadState);

    const modal = useModal();
    const filestack = require("filestack-js");
    const history = useHistory();
    const FILESTACK_APIKEY = process.env.REACT_APP_FILESTACK_APIKEY;
    const filestack_client = filestack.init(FILESTACK_APIKEY); // Client
    const [errorImages, setErrorImages] = useState([]);
    let uid;
    const userId = localStorage.getItem('uniqueUserId');
    if (!userId) {
        uid = uniqid();
        localStorage.setItem("uniqueUserId", uid);
    } else {
        uid = localStorage.getItem("uniqueUserId");

    }

    useEffect(() => {
        const filteredArray = imagesBlob.filter((blob) => !errorImages.includes(blob.id));
        setImagesBlob(() => [
            ...filteredArray,
        ]);
    }, [errorImages]);

    const hideUploadOptions = () => {
        modal('hide', 'uploadOptions', {}, 300)
    }

    const blobs = (res) => {
        const newImages = [];
        Object.values(res).forEach(val => val.forEach(r => {
            const imageUniqueId = uniqid();
            const imageUploadedAt = new Date().getTime();
            newImages.push({url: r.url, id: imageUniqueId, uploadedAt: imageUploadedAt});
            setImagesBlob((oldImagesBlob) => [
                ...oldImagesBlob,
                {
                    url: r.url,
                    id: imageUniqueId,
                    uploadedAt: imageUploadedAt,
                },
            ]);
            r.uniqueId = imageUniqueId;
            r.uploadedAt = imageUploadedAt;
        }));
        return newImages;
    }

    const uploadFromFile = (e) => {
        setUploadImages(true);
        if (imagecount + e.target.files.length > 30) {
            alert("לא ניתן להעלות יותר מ30 תמונות");
            modal('hide', 'uploadOptions');
            return;
        }

        setLoader((prevState) => ({
            ...prevState,
            showLoader: true,
            show50: true,
        }));

        const frametype = localStorage.getItem("frameSelected");

        hideUploadOptions();
        history.push("/review-your-images");

        for (let i = 0; i < e.target.files.length; i++) {
            const formdata = new FormData();
            let img = document.createElement("img");

            const newFile = e.target.files[i];
            const fileUniqueId = uniqid();
            const fileUploadedAt = new Date().getTime() + i;
            img.src = URL.createObjectURL(newFile);

            setImagesBlob((prevState) => [
                ...prevState,
                {
                    url: img.src,
                    id: fileUniqueId,
                    uploadedAt: fileUploadedAt,
                },
            ]);

            img.onload = function () {
                formdata.append("image", newFile);
                formdata.append("uid", uid);
                formdata.append("uniqueId", fileUniqueId);
                formdata.append("uploadedAtTime", fileUploadedAt);
                formdata.append("imagewidth", img.width);
                formdata.append("imageheight", img.height);
                formdata.append("imageext", 0);
                formdata.append("frametype", frametype);

                uploadSingleImage(formdata)
                  .then((res) => {
                      localStorage.setItem('dataExist', 1);
                  })
                  .catch((err) => {
                      setErrorImages((prevState) => [
                          ...prevState,
                          fileUniqueId,
                      ]);
                      console.log(err);
                  });
            };
        }

        /*if (e.target.files.length === 1) {

            let img = document.createElement("img");

            const newFile = e.target.files[0];
            const fileUniqueId = uniqid();
            const fileUploadedAt = new Date().getTime();
            img.src = URL.createObjectURL(newFile);

            setImagesBlob((prevState) => [
                ...prevState,
                {
                    url: img.src,
                    id: fileUniqueId,
                    uploadedAt: fileUploadedAt,
                },
            ]);

            img.onload = function () {
                formdata.append("image", newFile);
                formdata.append("uid", uid);
                formdata.append("uniqueId", fileUniqueId);
                formdata.append("uploadedAtTime", fileUploadedAt);
                formdata.append("imagewidth", img.width);
                formdata.append("imageheight", img.height);
                formdata.append("imageext", 0);
                formdata.append("frametype", frametype);

                uploadSingleImage(formdata)
                    .then((res) => {
                        /!*console.log(res);*!/
                        localStorage.setItem('dataExist', 1);

                        if(imagesBlob > 0){

                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            };
        } else {
            let promises = [];
            for (let i = 0; i < e.target.files.length; i++) {

                promises.push(new Promise((res, _rej) => {
                    let files = e.target.files[i];
                    let img = document.createElement("img");
                    const fileUniqueId = uniqid();
                    const fileUploadedAt = new Date().getTime() + i;
                    img.src = URL.createObjectURL(files);

                    setImagesBlob((oldImagesBlob) => [
                        ...oldImagesBlob,
                        {
                            url: img.src,
                            id: fileUniqueId,
                            uploadedAt: fileUploadedAt,
                        },
                    ]);

                    img.onload = function () {
                        formdata.append("imagewidth", img.width);
                        formdata.append("imageheight", img.height);
                        formdata.append("imageext", 0);
                        formdata.append("image", files);
                        formdata.append("uniqueId", fileUniqueId);
                        formdata.append("uploadedAtTime", fileUploadedAt);
                        formdata.append("uid", uid);
                        formdata.append("frametype", frametype);
                        formdata.append("source", '151122');
                        res();
                    };
                }))
            }

            //https://stickable-admin.yeshostings.com
            Promise.all(promises).then(() => uploadMultipleImages(formdata));
        }*/
    };

    const uploadOptions = {
        accept: ["image/*"],
        maxFiles: 30 - imagecount,
        uploadInBackground: false,
        onUploadDone: async (res) => {
            blobs(res);

            res.uid = uid;

            setLoader((prevState) => ({
                ...prevState,
                showLoader: true,
                show50: true,
            }));
          history.push("/review-your-images");
          await addImageFromSocial(res);
        },
    };

    const facebookPhotoImport = () => {
        hideUploadOptions();
        const options = {
            fromSources: ["facebook"],
            ...uploadOptions
        }

        filestack_client.picker(options).open();
    };

    const instagramPhotoImport = () => {
        hideUploadOptions();
        const options = {
            fromSources: ["instagram"],
            ...uploadOptions
        };

        filestack_client.picker(options).open();
    };

    return (
        <div className={cn("upload-options", {open: isOpen})} style={{...style}}>
            <div className="upload-options__item" style={{fontWeight: "500"}}>
                בחירת תמונות מהמכשיר<img src="/assets/file/images/upload-file.svg" alt="file"/>
                {isOpen && <input
                    className="file-input"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => uploadFromFile(e)}
                />}
            </div>
            <button onClick={() => facebookPhotoImport()} className="upload-options__item"
                    style={{color: "black", fontWeight: "500"}}>
                ייבוא מהפייסבוק <img src="/assets/file/images/upload-fb.svg" alt="facebook"/>
            </button>
            <button onClick={() => instagramPhotoImport()} className="upload-options__item"
                    style={{color: "black", fontWeight: "500"}}>
                ייבוא מהאינסטגרם <img src="/assets/file/images/upload-inst.svg" alt="instagram"/>
            </button>
        </div>
    );
};

export default UploadOptions;

