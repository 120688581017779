
import useWindowDimensions from "@helpers/hooks/windowDemensions";
export const Truck = () => {
  const { height, width } = useWindowDimensions();
  const isMobile = width < 767
    return (
    <svg width={isMobile ? 30 : 50} height={isMobile ? 30 : 50} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38.7501 5.16667V31C38.7501 33.8417 36.4251 36.1667 33.5834 36.1667H5.16675V19.685C7.05258 21.9325 9.94598 23.3275 13.1493 23.25C15.7585 23.1983 18.1093 22.1908 19.8659 20.5117C20.6668 19.84 21.3385 18.9875 21.8551 18.0575C22.7851 16.4816 23.3017 14.6216 23.2501 12.6841C23.1726 9.6616 21.8293 7.00084 19.7368 5.16667H38.7501Z" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.8334 36.1667V43.9167C56.8334 48.205 53.3718 51.6667 49.0834 51.6667H46.5001C46.5001 48.825 44.1751 46.5 41.3334 46.5C38.4918 46.5 36.1667 48.825 36.1667 51.6667H25.8334C25.8334 48.825 23.5084 46.5 20.6667 46.5C17.8251 46.5 15.5001 48.825 15.5001 51.6667H12.9167C8.62842 51.6667 5.16675 48.205 5.16675 43.9167V36.1667H33.5834C36.4251 36.1667 38.7501 33.8417 38.7501 31V12.9167H43.5035C45.3635 12.9167 47.0685 13.9242 47.9985 15.5259L52.4159 23.25H49.0834C47.6626 23.25 46.5001 24.4125 46.5001 25.8333V33.5833C46.5001 35.0042 47.6626 36.1667 49.0834 36.1667H56.8334Z" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.6667 56.8333C23.5201 56.8333 25.8333 54.5201 25.8333 51.6667C25.8333 48.8132 23.5201 46.5 20.6667 46.5C17.8132 46.5 15.5 48.8132 15.5 51.6667C15.5 54.5201 17.8132 56.8333 20.6667 56.8333Z" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M41.3334 56.8333C44.1869 56.8333 46.5001 54.5201 46.5001 51.6667C46.5001 48.8132 44.1869 46.5 41.3334 46.5C38.4799 46.5 36.1667 48.8132 36.1667 51.6667C36.1667 54.5201 38.4799 56.8333 41.3334 56.8333Z" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.8333 31V36.1667H49.0833C47.6625 36.1667 46.5 35.0042 46.5 33.5833V25.8333C46.5 24.4125 47.6625 23.25 49.0833 23.25H52.4158L56.8333 31Z" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.25 12.6841C23.3016 14.6216 22.785 16.4817 21.855 18.0575C21.3383 18.9875 20.6666 19.8401 19.8658 20.5117C18.1091 22.1909 15.7584 23.1984 13.1492 23.25C9.94586 23.3275 7.05246 21.9325 5.16662 19.685C4.80496 19.2975 4.49497 18.8584 4.2108 18.4192C3.2033 16.8951 2.63496 15.0868 2.58329 13.1493C2.50579 9.89428 3.95244 6.92336 6.27744 4.98586C8.0341 3.53919 10.2557 2.63505 12.6841 2.58338C15.3966 2.53171 17.8767 3.51338 19.7367 5.16671C21.8292 7.00088 23.1725 9.66164 23.25 12.6841Z" stroke="#292D32" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.88672 12.9942L11.4959 15.4741L16.895 10.2558" stroke="#292D32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}
