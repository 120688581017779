import React from 'react';
import './GoogleReviews.css';

const GoogleReview = () => {
  return (
    <div className='google-container-mob'>
      <div className="review-line-mob">
        <img src="/assets/images/google.svg" alt="Google" className="googlehp-mob" />
        <img src="/assets/images/stars.svg" alt="Stars" className="starshp-mob" />
        <p className="googlereviewhp-mob">4.9/5</p>
      </div>
      <p className='basedontext-mob'>מתוך אלפי תמונות של לקוחות מרוצים</p>
    </div>
  );
};

export default GoogleReview;
