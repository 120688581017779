import axios from "axios";
import React, {useState} from "react";
import {isAndroid, isIOS} from "react-device-detect";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {imagesBlobState, secondaryModals, shouldValidateState, selectedShippingAddress} from "@atoms";
import {
  netPriceState,
  imageCountState,
} from "@atoms/priceCalc";
import "./style.css";
import {checkOrderStatus} from "../../../api/requests";
import {OrderStatus} from "../../../constants/enums/orderStatus";
import AmountCharged from "../../../helpers/AmountCharged";
import {useSecondModal} from "../../../helpers/hooks/useSecondModal";
import {validCoupon} from "../../../Recoil/Atoms";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SHIPMENT_COST = 29.90;

const SelectCardModalMobile = ({style}) => {
  const setModals = useSetRecoilState(secondaryModals);
  const [validation, setValidation] = useRecoilState(shouldValidateState);
  const selectedAddress = useRecoilValue(selectedShippingAddress);
  const modal = useSecondModal();
  const [isLoading, setIsLoading] = useState(false);

  const imageCount = useRecoilValue(imageCountState);
  const imagesBlob = useRecoilValue(imagesBlobState);
  const [numberOfImages] = useState(5);
  const netPrice = useRecoilValue(netPriceState);
  const selectedCoupon = useRecoilValue(validCoupon);
  const amountCharged = AmountCharged(imageCount + imagesBlob.length, numberOfImages,
    netPrice + (imagesBlob.length * 45), SHIPMENT_COST, selectedCoupon);

  const handleOpenNewCardForm = (shouldValidate = true) => {
    setValidation(shouldValidate);

    setModals(md => ({
      ...md,
      selectCardMobile: {visible: false},
      addCardMobile: {visible: true},
    }));
  };

  const creatOrder = async (data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${BASE_URL}/gama-createorder`, data, config);
      if (res.data.status === 200) {
        console.log("----success-----");
      }
      return res.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleBitPayment = async () => {
    setIsLoading(true);

    let haveToCreate = true;
    let gamaAuthResponse;
    let bitModalData;
    const gamaAccessToken = localStorage.getItem("gamaAccessToken");
    const expiresAt = localStorage.getItem("gamaAccessTokenExpiresAt");

    try {
      if (localStorage.getItem("orderId")) {
        const checkResp = await checkOrderStatus();
        if (checkResp.data && checkResp.data.orderStatus === OrderStatus.NOT_PAID) {
          haveToCreate = false;
        } else {
          localStorage.removeItem("orderId");
          throw new Error("Order is already paid or failed");
        }
      }

      if (!gamaAccessToken || expiresAt <= Date.now()) {
        gamaAuthResponse = await axios.post(`${BASE_URL}/gama-login`);

        if (gamaAuthResponse.data.status !== 200) {
          throw new Error("Gama auth failed.");
        }

        localStorage.setItem("gamaAccessToken", gamaAuthResponse.data.access_token);
        localStorage.setItem("gamaAccessTokenExpiresAt", (Date.now() + gamaAuthResponse.data.expires_in));
      }

      const gamaSessionResponse = await axios.post(`${BASE_URL}/gama-session`, {
        gamaAccessToken: localStorage.getItem("gamaAccessToken"),
      });

      if (gamaSessionResponse.data.status !== 200) {
        throw new Error("Gama session creating failed.");
      }

      if (haveToCreate) {
        const orderData = await creatOrder(
          {
            ...selectedAddress,
            uid: localStorage.getItem("uniqueUserId"),
            finalAmount: amountCharged,
            orderStatus: OrderStatus.NOT_PAID,
            imagesPayed: imageCount + imagesBlob.length,
          });

        if (!orderData) {
          throw new Error("Creating order failed.");
        }

        localStorage.setItem("orderId", orderData.oid);
      }

      const id = localStorage.getItem("orderId");

      const createTransactionResponse = await axios.post(`${BASE_URL}/gama-create-payment`, {
        orderId: id,
        amount: amountCharged,
        payerName: selectedAddress.fullName,
        payerPhoneNumber: selectedAddress.phoneNumber,
        transactionId: gamaSessionResponse.data.transactionProcessingId,
        gamaAccessToken: localStorage.getItem("gamaAccessToken"),
        gamaSessionToken: gamaSessionResponse.data.token,
        transactionEndpoint: gamaSessionResponse.data.transactionProcessingEndpoint,
      });

      if (createTransactionResponse.data.status !== 200) {
        throw new Error("Gama transaction creating failed.");
      }

      if (isAndroid) {
        bitModalData = {
          amountCharged,
          strLink: createTransactionResponse.data.walletProviderApplicationSchemeAndroid,
        };
        console.log("Android");
      } else if (isIOS) {
        bitModalData = {
          amountCharged,
          strLink: createTransactionResponse.data.walletProviderApplicationSchemeIos,
        };
        console.log("iOS");
      }

      setModals(state => ({
        ...state,
        selectCardMobile: {visible: false},
        bitPayMobile: {visible: true, data: bitModalData},
      }));
    } catch (err) {
      console.log(err);
      modal("open", "errorCart");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-card-modal-mobile" style={{...style}}>
      <button onClick={() => handleOpenNewCardForm()} style={{color: "black", fontWeight: "500"}}>
        שלמו בכרטיס אשראי
        <img src="/assets/images/card-box-pos.svg" alt="add-new-card" />
      </button>
      <ul>
        <button className="bitpayment" onClick={() => handleBitPayment()} disabled={isLoading}>
          שלמו בביט
          {isLoading && <span className="buttonspinner"></span>}
          <img src="/assets/images/bitcart.png" alt="bitpayment" />
        </button>
      </ul>
      {/* <ul>
        <button onClick={() => handleOpenNewCardForm(false)} style={{color: "black", fontWeight: "500"}}>
        כרטיס דביט\דיירקט
          <img src="/assets/images/credit-pos.svg" alt="add-new-card" />
        </button>
      </ul> */}
    </div>
  );
};

export default SelectCardModalMobile;
