import React from "react";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "@helpers/hooks/windowDemensions";
import { useSecondModal } from "@helpers/hooks/useSecondModal";
import "./footer.css";
import { useRecoilState } from 'recoil'
import { aboutUs } from '@atoms';
import {Facebook} from './facebook'
import {Instagram} from './instagram'

const Footer = ({existData}) => {
  const { width } = useWindowDimensions();
  const modal = useSecondModal();
  const [, setAboutUs] = useRecoilState(aboutUs);

  const handleAboutUsModal = (type) => {
    setAboutUs(type)
    if (width > 768) {
      modal('open', 'aboutUs')
    } else {
      modal('open', 'aboutUsMobile');
    }
  }

  return (
    <React.Fragment>
      <footer className="site-footer"  style={{paddingRight: 0, paddingLeft: 0, display: width > 767 ? "flex" : "block",  justifyContent: 'center'}}>
          <div className="footer-row">
            <div className="footer-logo">
              {/* <div style={{border: 'solid #C6DBCD 1px', marginTop: '15px', marginLeft: '35px', marginRight: '35px'}} />
              {width < 767 && <div style={{marginTop: '20px'}} className="footer-additional-links-container">
                <div className="footer-ul-li">
                  <div  className="footer-link">
                    <span className="footer-links-title-mobile">שאלות מהירות</span>
                  </div>
                  <div className="footer-link">
                    <NavLink to="#" onClick={() => handleAboutUsModal('HT')} style={{color: width < 767 ? "#304036" : "#000000"}}>
                    + כיצד לרכוש בבלנדס
                    </NavLink>
                  </div>
                  <div className="footer-link">
                    <NavLink to="#" onClick={() => handleAboutUsModal('CQ')} style={{color: width < 767 ? "#304036" : "#000000"}}>
                    + מה הוא גודל המסגרות  
                    </NavLink>
                  </div>
                  <div className="footer-link">
                    <NavLink to="#" onClick={() => handleAboutUsModal('CQ')} style={{color: width < 767 ? "#304036" : "#000000"}}>
                    + מה זה בלנדס
                    </NavLink>
                  </div>
                  <div className="footer-link">
                    <NavLink to="#" onClick={() => handleAboutUsModal('CQ')} style={{color: width < 767 ? "#304036" : "#000000"}}>
                    + אפשר להדביק בלנדס על כל סוגי הקירות?
                    </NavLink>
                  </div>
                </div>
              </div>}
              <div className="as-seen-on-mobile">
                      <p>הופענו ב</p>
              </div>
                      <div className="as-seen-on-mobile">
                        <img src="/assets/images/asseenon.png" alt="הופענו בישראל היום וערוץ 7"/>
                      </div> */}
            </div>
            {/* Middle Button */}
            <div className="footer-column" style={{order:"2"}}>
              <div className="foo-content">
                <div className="foo-wrap">
                  <h5>יש לכם שאלה? רוצים לדבר איתנו?</h5>
                  <p>דברו עם נציג מצוות התמיכה והשירות.</p>
                </div>
                <img src="/assets/images/wa.svg" alt="דברו עם בלנדס בוואצאפ" />
                <div className="ex-wrap">
                  <a
                    onClick={() => {
                      if (width > 767) {
                        modal('open', 'whatsApp');
                      } else {
                        modal('open', 'whatsAppMobile');
                      }
                    }}
                    className="link"
                    title="leads to a Q&A screen"
                    style={{color: "#087E63", cursor: "pointer", background: '#FFF', border: "solid 1px #087E63"}}
                  >
                    דברו איתנו
                  </a>
                </div>
              </div>
            </div>
            {/* Default Links */}
            <div className={`${width < 767 ? 'footer-column' : "desktop-footer-column"}`} style={{order: width < 767 ? "1" : "4"}}>

              <div className="foo-menu-wrap" style={{order: width <= 768 ? 2 : 1, marginTop: "0" }}>
                <span className="footer-links-title-desktop" style={{marginBottom: "10px"}}>עקבו אחרינו</span>
                <div>
                  <div>
                  <a href="https://www.facebook.com/profile.php?id=100086212847385"  className="footer-link" style={{marginRight: width < 767 ? '6.25px' : 0}}>
                      פייסבוק
                    </a>
                    {  width <= 768 && <Facebook />}
                  </div>
                  <div>
                  <a href="https://www.instagram.com/blends_il/" className="footer-link" style={{marginRight: width < 767 ? '6.25px' : 0}}>
                      אינסטגרם
                    </a>
                  { width <= 768 && <Instagram /> }
                   
                  </div>
                </div>
              </div>
              <div className="foo-menu-wrap" style={{order: width <= 768 ? 1 : 2, marginLeft: width < 767 ? 0 : '50px', marginTop: "0"  }} >
                {width >= 768 && <span className="footer-links-title-desktop">אודותינו</span>}
                <div>
                <div className="mobile-payment-footer">
                {width <= 768 && <img src="/assets/images/BulkPayment.png" alt="" />}
                </div>
                  <div className="footer-link">
                    <NavLink to="#" onClick={() => handleAboutUsModal('AS')} style={{color: width < 767 ? "#304036" : "#000000"}}>
                    הצהרת נגישות
                    </NavLink>
                  </div>
                  <div className="footer-link">
                    <NavLink to="#" onClick={() => handleAboutUsModal('PP')} style={{color: width < 767 ? "#304036" : "#000000"}}>
                    מדיניות פרטיות
                    </NavLink>
                  </div>
                  <div className="footer-link">
                    <NavLink to="#" onClick={() => handleAboutUsModal('CQ')} style={{color: width < 767 ? "#304036" : "#000000"}}>
                    שאלות נפוצות
                    </NavLink>
                  </div>
                  <div className="footer-link">
                    <NavLink to="#" onClick={() => handleAboutUsModal('SR')} style={{color: width < 767 ? "#304036" : "#000000"}}>
                    משלוחים והחזרות
                    </NavLink>
                  </div>
                  
                  {width < 767 && <div className="footer-link">
                    <NavLink to="#" onClick={() => handleAboutUsModal('HT')} style={{color: width < 767 ? "#304036" : "#000000"}}>
                    כיצד לרכוש בבלנדס
                    </NavLink>
                  </div>}
                </div>
              </div>
            </div>
            {/* Payments */}
            <div className="footer-column" style={{order: width < 767 ? "3" : "1"}}>
              <p>אנחנו עובדים עם</p>
              {width >= 767 && <img src="/assets/images/BulkPayment.png" alt="" />}
            </div>
          </div>
      </footer>
      <section className="footer-btm" style={{justifyContent: "center"}}>
        <div style={{display: "flex", maxWidth: "1110px", justifyContent: "center", width: "100%", display: width <= 767 ? "none" : "flex"}}>
          <p style={{lineHeight: "36px", margin: "0"}}>Copyright Blends 2021-{new Date().getFullYear()} &copy;</p>
          <div className="btm-wrap">
            <NavLink
              to="#"
              title="מדיניות פרטיות"
              data-target="#float2"
              data-toggle="modal"
              style={{fontSize: "14px", color: "#C8C8C8", fontWeight: "300", lineHeight: "36px", textDecorationLine: "underline"}}
              onClick={() => handleAboutUsModal('PP')}
            >
              מדיניות פרטיות
            </NavLink>
            <NavLink
              to="#"
              title="הצהרת נגישות"
              data-target="#float3"
              data-toggle="modal"
              style={{fontSize: "14px", color: "#C8C8C8", fontWeight: "300", lineHeight: "36px", textDecorationLine: "underline"}}
              onClick={() => handleAboutUsModal('AS')}
            >
              הצהרת נגישות
            </NavLink>
          </div>
        </div>
      </section>
      <div className="modal fade" id="float3">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title"
                style={{ width: "100%", textAlign: "center" }}
              >
                תנאי שימוש
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body" style={{ minHeight: "300px" }}></div>

            <div className="modal-footer text-center justify-content-center">
              <button
                type="button"
                className="btn btn-secondary ftt"
                data-dismiss="modal"
              >
                יקוא
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="float2">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title"
                style={{ width: "100%", textAlign: "center" }}
              >
                מדיניות פרטיות
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body" style={{ minHeight: "300px" }}></div>

            <div className="modal-footer text-center justify-content-center">
              <button
                type="button"
                className="btn btn-secondary ftt"
                data-dismiss="modal"
              >
              אוקי
              </button>
            </div>
          </div>
        </div>
      </div>
      {width <= 768 && (
        <div className="mobile-stick-bottom" style={{fontWeight: 300, color: "#727272"}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
            {/* <p style={{marginRight: '8px'}}> 
              זמני המשלוח מהירים במיוחד השבוע 
            </p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14H13C14.1 14 15 13.1 15 12V2H6C4.5 2 3.19001 2.82999 2.51001 4.04999" stroke="#727272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoinn="round"/>
              <path d="M2 17C2 18.66 3.34 20 5 20H6C6 18.9 6.9 18 8 18C9.1 18 10 18.9 10 20H14C14 18.9 14.9 18 16 18C17.1 18 18 18.9 18 20H19C20.66 20 22 18.66 22 17V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L18.58 6.01001C18.22 5.39001 17.56 5 16.84 5H15V12C15 13.1 14.1 14 13 14H12" stroke="#727272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoinn="round"/>
              <path d="M8 22C9.10457 22 10 21.1046 10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 21.1046 6.89543 22 8 22Z" stroke="#727272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoinn="round"/>
              <path d="M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z" stroke="#727272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoinn="round"/>
              <path d="M22 12V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L22 12Z" stroke="#727272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoinn="round"/>
              <path d="M2 8H8" stroke="#727272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoinn="round"/>
              <path d="M2 11H6" stroke="#727272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoinn="round"/>
              <path d="M2 14H4" stroke="#727272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoinn="round"/>
            </svg> */}
          </div>
          
          {existData || localStorage.getItem('dataExist') == 1 ? (
            <NavLink to="/review-your-images" className="footer-btn">
            ממשיכים בהזמנה            
            </NavLink>
            ) : (
            <NavLink to="/upload-your-image" className="footer-btn">
          בואו נתחיל            
          </NavLink>
            )
          }
        </div>
      )}
    </React.Fragment>
  );
}

export default Footer;
